import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import * as FpMap from "fp-ts/lib/Map";
import * as FpString from "fp-ts/lib/string";
import log from "loglevel";

export interface UserQueueCursor {
  pageKey: string;
  cursor: string;
}

export interface UserQueueFilters {
  behaviors: Array<string>;
  languages: Array<string>;
  products: Array<string>;
  includeUnknownLanguages: boolean;
}

interface UserQueueState {
  filters: UserQueueFilters;
  cursors: Map<string, string>;
}

@Module({
  name: "userQueue",
  stateFactory: true,
  namespaced: true
})
export default class UserQueueStore extends VuexModule {
  public userQueueState: UserQueueState = {
    filters: {
      behaviors: [],
      languages: [],
      products: [],
      includeUnknownLanguages: false
    },
    cursors: new Map()
  };

  get filterValues() {
    return this.userQueueState.filters;
  }

  get behaviors() {
    return this.userQueueState.filters.behaviors;
  }

  get languages() {
    return this.userQueueState.filters.languages;
  }

  get products() {
    return this.userQueueState.filters.products;
  }

  @Action
  updateCursor(cursor: UserQueueCursor) {
    this.context.commit("setCursor", { ...cursor });
  }

  @Action
  updateFilters(filters: UserQueueFilters) {
    this.context.commit("setFilters", { ...filters });
  }

  @Mutation
  setCursor({ pageKey, cursor }: UserQueueCursor) {
    log.info(`committing cursor ${cursor}, pageKey: ${pageKey}`);
    this.userQueueState.cursors = FpMap.upsertAt(FpString.Eq)(pageKey, cursor)(
      this.userQueueState.cursors
    );
  }

  @Mutation
  setFilters(userQueueFilters: UserQueueFilters) {
    log.info("setting filters to", userQueueFilters);
    this.userQueueState.filters = userQueueFilters;
    this.userQueueState.cursors = new Map();
  }
}

import Vue from "vue";
import { ModalEventType, ModalType } from "~/constants/modals";

export interface Modal {
  eventBus: Vue;
  open: (type: ModalType) => void;
  close: (type: ModalType) => void;
  updateData: (data: any) => void;
}

export class ModalImpl implements Modal {
  eventBus = new Vue();

  open(type: ModalType) {
    this.eventBus.$emit(ModalEventType.OPEN, type);
  }

  close(type: ModalType) {
    this.eventBus.$emit(ModalEventType.CLOSE, type);
  }

  updateData(data: any) {
    this.eventBus.$emit(ModalEventType.UPDATE, data);
  }
}

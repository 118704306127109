export const timeFormat = new Intl.DateTimeFormat("en", {
  hour: "numeric",
  minute: "numeric"
});

export const dateFormat = new Intl.DateTimeFormat("en", {
  year: "numeric",
  month: "short",
  day: "2-digit"
});

export const numberFormat = new Intl.NumberFormat("en", { style: "decimal" });

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e3d2d06 = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _7a9810f0 = () => interopDefault(import('../pages/behavior-trends.vue' /* webpackChunkName: "pages/behavior-trends" */))
const _74274ebf = () => interopDefault(import('../pages/clients.vue' /* webpackChunkName: "pages/clients" */))
const _2dc0f568 = () => interopDefault(import('../pages/list-management/index.vue' /* webpackChunkName: "pages/list-management/index" */))
const _1c46cc3a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _495a5923 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _08ebe379 = () => interopDefault(import('../pages/manage-users.vue' /* webpackChunkName: "pages/manage-users" */))
const _0e8bfd6c = () => interopDefault(import('../pages/navigate.vue' /* webpackChunkName: "pages/navigate" */))
const _4aa2353c = () => interopDefault(import('../pages/user-moderation/index.vue' /* webpackChunkName: "pages/user-moderation/index" */))
const _6edb7877 = () => interopDefault(import('../pages/list-management/_list.vue' /* webpackChunkName: "pages/list-management/_list" */))
const _73ca4f6c = () => interopDefault(import('../pages/user-moderation/_id.vue' /* webpackChunkName: "pages/user-moderation/_id" */))
const _cd5b0f6e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _2e3d2d06,
    name: "analytics"
  }, {
    path: "/behavior-trends",
    component: _7a9810f0,
    name: "behavior-trends"
  }, {
    path: "/clients",
    component: _74274ebf,
    name: "clients"
  }, {
    path: "/list-management",
    component: _2dc0f568,
    name: "list-management"
  }, {
    path: "/login",
    component: _1c46cc3a,
    name: "login"
  }, {
    path: "/logout",
    component: _495a5923,
    name: "logout"
  }, {
    path: "/manage-users",
    component: _08ebe379,
    name: "manage-users"
  }, {
    path: "/navigate",
    component: _0e8bfd6c,
    name: "navigate"
  }, {
    path: "/user-moderation",
    component: _4aa2353c,
    name: "user-moderation"
  }, {
    path: "/list-management/:list?",
    component: _6edb7877,
    name: "list-management-list"
  }, {
    path: "/user-moderation/:id?",
    component: _73ca4f6c,
    name: "user-moderation-id"
  }, {
    path: "/",
    component: _cd5b0f6e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

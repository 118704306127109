import { Context } from "@nuxt/types/app";
import middlewareFactory from "./middlewareFactory";
/**
 * Middleware which is meant for pages where the user is only supposed to go when he is not authenticated.
 * eg the login page. If the user is trying to navigate to the login page while he is logged in
 * then he will be redirected to the home page.
 */

const check = (context: Context) => context.app.$authService.isAuthenticated;
const actionOnTrue = (context: Context) => () => context.redirect("/");
const notAuthenticated = middlewareFactory(check)(() => () => {}, actionOnTrue);

export default notAuthenticated;

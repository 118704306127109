import log from "loglevel";
import { pipe } from "fp-ts/function";
import * as FpArray from "fp-ts/Array";

import { Plugin } from "@nuxt/types";
import { SpcAuthClient } from "~/services/spcAuthClient";
import { SpcAuthClientMockImpl } from "~/services/mock/spcAuthClientMockImpl";

let $spcAuthClient: SpcAuthClient;

const auth0MockPlugin: Plugin = (_, inject) => {
  log.info("starting Auth0 mock Bootstrap plugin");

  const query = window.location.search.substr(1);
  const parameters = pipe(
    query.split("&"),
    FpArray.reduce({} as { [key: string]: string }, (accumulator, queryPart) => {
      const items = queryPart.split("=");
      accumulator[decodeURIComponent(items[0])] = decodeURIComponent(items[1]);
      return accumulator;
    })
  );

  // eslint-disable-next-line
  const { access_token, id_token, expires_in } = parameters;
  // eslint-disable-next-line
  $spcAuthClient = new SpcAuthClientMockImpl(access_token, id_token, expires_in);
  inject("spcAuthClient", $spcAuthClient);
  Promise.resolve(undefined); // To make the mock plugin return a promise like the auth0Plugin
};

function getMockClient() {
  return {
    $spcAuthClient
  };
}

export { auth0MockPlugin, getMockClient };


import { pipe } from "fp-ts/function";
import * as Str from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as S from "fp-ts/lib/Set";
import * as TE from "fp-ts/lib/TaskEither";
import "reflect-metadata";
import log from "loglevel";
import Vue, { PropType } from "vue";
import { ButtonType } from "@spectrum/pandora";
import {
  BulkWebhookInfo,
  WebhookBulkModalData,
  WebhookEvent,
  WebhookNotFoundForSubscriptionError,
  WebhookTagEvent,
  TagName
  // WebhooksToBulkInfo
} from "~/services/webhooks/models/WebhookModels";
import BulkActionsTable from "~/components/override-actions/bulk-level/BulkActionsTable.vue";
import { ModalType } from "~/constants/modals";
import { bulkRecordsToStatusRequests } from "~/services/webhooks/utils/BulkWebhookModalUtils";
import { ToggleCaseEvent } from "~/plugins/eventHub";
import { getAuthService } from "~/plugins/appServices";

export default Vue.extend({
  name: "BulkOverrideActions",
  components: { BulkActionsTable },
  props: {
    webhookBulkModalData: { required: true, type: Object as PropType<WebhookBulkModalData> }
  },
  data() {
    return {
      webhooksToBulkInfo: this.webhookBulkModalData.webhooksToBulkInfo,
      overrideNotes: this.webhookBulkModalData.overrideNotes,
      refreshCases: () => this.webhookBulkModalData.refreshFn(),
      showActionsDisplayError: false,
      showDisplayWebhookError: false,
      actionsDisplayErrorMessage: "Cannot display actions for case, please try again later!",
      confirmButtonType: ButtonType.CONFIRM
    };
  },
  computed: {
    isLoading() {
      const loader = this.$nuxt.$loading;
      // N.B. the syntax below attempts to narrow down to DefaultNuxtLoading to use the show method
      return "show" in loader && loader.show;
    },
    allTagsForClient() {
      return this.webhookBulkModalData.allTagsForClient;
    },
    userId() {
      return this.webhookBulkModalData.userId;
    }
  },
  watch: {
    webhookBulkModalData() {
      this.overrideNotes = this.webhookBulkModalData.overrideNotes;
      this.webhooksToBulkInfo = this.webhookBulkModalData.webhooksToBulkInfo;
    }
  },
  mounted() {
    this.$nuxt.$eventHub.on("toggleCase", this.handleToggleCase);
  },
  destroyed() {
    this.$nuxt.$eventHub.off("toggleCase");
  },
  methods: {
    handleToggleTag(webhookTagEvent: WebhookTagEvent) {
      const { tagName, subscriptionName, subscriptionId } = webhookTagEvent;
      pipe(
        this.webhooksToBulkInfo,
        R.lookup(subscriptionId),
        O.chain((w) => {
          const activeTags = pipe(w.activeTags, S.toggle(Str.Eq)(tagName));
          const disabledTags = pipe(w.disabledTags, S.toggle(Str.Eq)(tagName));
          const updatedW = { ...w, activeTags, disabledTags } as BulkWebhookInfo;
          return pipe(this.webhooksToBulkInfo, R.updateAt(subscriptionId, updatedW));
        }),
        E.fromOption(() => new WebhookNotFoundForSubscriptionError(subscriptionName)),
        E.fold(
          (e) => {
            log.error(e, `Received error on tag toggle for [webhook: ${subscriptionName}]`);
            this.showActionsDisplayError = true;
          },
          (updatedWebhooksToInfo) => {
            log.info(`Toggled tag for [webhook: ${subscriptionName}, tag: ${tagName}]`);
            this.showActionsDisplayError = false;
            this.webhooksToBulkInfo = updatedWebhooksToInfo;
          }
        )
      );
    },
    handleAddTag(webhookTagEvent: WebhookTagEvent) {
      const { tagName, subscriptionName, subscriptionId } = webhookTagEvent;
      pipe(
        this.webhooksToBulkInfo,
        R.lookup(subscriptionId),
        O.chain((w) => {
          const addedTagSet = S.singleton<TagName>(tagName);
          const additionalTags = pipe(w.additionalTags, S.union(Str.Eq)(addedTagSet));
          const activeTags = pipe(w.activeTags, S.union(Str.Eq)(addedTagSet));
          const disabledTags = pipe(w.disabledTags, S.difference(Str.Eq)(addedTagSet));
          const updatedW = { ...w, activeTags, disabledTags, additionalTags } as BulkWebhookInfo;
          return pipe(this.webhooksToBulkInfo, R.updateAt(subscriptionId, updatedW));
        }),
        E.fromOption(() => new WebhookNotFoundForSubscriptionError(subscriptionName)),
        E.fold(
          (e) => {
            log.error(e, `Received error on adding a tag for [webhook: ${subscriptionName}]`);
            this.showActionsDisplayError = true;
          },
          (updatedWebhooksToInfo) => {
            log.info(`Added tag for [webhook: ${subscriptionName}, tag: ${tagName}]`);
            this.showActionsDisplayError = false;
            this.webhooksToBulkInfo = updatedWebhooksToInfo;
          }
        )
      );
    },
    handleToggleAction(webhookEvent: WebhookEvent) {
      const { subscriptionName, subscriptionId } = webhookEvent;
      pipe(
        this.webhooksToBulkInfo,
        R.lookup(subscriptionId),
        O.chain((w) => {
          const updatedW = { ...w, selected: !w.selected } as BulkWebhookInfo;
          return pipe(this.webhooksToBulkInfo, R.updateAt(subscriptionId, updatedW));
        }),
        E.fromOption(() => new WebhookNotFoundForSubscriptionError(subscriptionName)),
        E.fold(
          (e) => {
            log.error(e, `Received error on toggling webhook for [webhook: ${subscriptionName}]`);
            this.showActionsDisplayError = true;
          },
          (updatedWebhooksToInfo) => {
            log.info(`Toggled webhook for [webhook: ${subscriptionName}]`);
            this.showActionsDisplayError = false;
            this.webhooksToBulkInfo = updatedWebhooksToInfo;
          }
        )
      );
    },
    handleToggleCase(toggleCaseEvent: ToggleCaseEvent) {
      const { caseId, subscriptionId } = toggleCaseEvent;
      pipe(
        this.webhooksToBulkInfo,
        R.lookup(subscriptionId),
        O.chain((bulkWebhookInfo) => {
          const updatedBulkInfo = {
            ...bulkWebhookInfo,
            selectedCase: O.some(caseId)
          };
          return pipe(this.webhooksToBulkInfo, R.updateAt(subscriptionId, updatedBulkInfo));
        }),
        E.fromOption(() => new Error(subscriptionId)),
        E.fold(
          (e) => {
            log.error(e, `Received error when selecting case for webhook [case: ${caseId}]`);
            this.showActionsDisplayError = true;
          },
          (updatedWebhooksToCaseSelector) => {
            log.info(`Selected case for [webhook: ${subscriptionId}]`);
            this.showActionsDisplayError = false;
            this.webhooksToBulkInfo = updatedWebhooksToCaseSelector;
          }
        )
      );
    },
    handleClickCancel() {
      this.overrideNotes = "";
      this.$modal.close(ModalType.BULK_OVERRIDE);
    },
    handleSaveAndOverride() {
      const { $authService } = getAuthService();
      const createdBy = $authService.userOrLogout().email;
      pipe(
        bulkRecordsToStatusRequests(this.webhooksToBulkInfo, createdBy),
        TE.of,
        TE.chain((updateCaseStatusRequests) =>
          this.$webhooksService.bulkOverrideWithUpdateRequests(updateCaseStatusRequests)
        ),
        TE.bimap(
          (e) => {
            log.error(e, `Error on bulk override for [user: ${this.webhookBulkModalData.userId}]`);
            this.showActionsDisplayError = true;
          },
          async (response) => {
            log.debug("Response for bulk override request", JSON.stringify(response.toObject()));
            this.showActionsDisplayError = false;
            this.overrideNotes = "";
            await this.refreshCases();
            this.$modal.close(ModalType.BULK_OVERRIDE);
          }
        )
      )();
    }
  }
});

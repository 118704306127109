
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component({
  layout: "main"
})
export default class Error extends Vue {
  @Prop({ default: { statusCode: "" } })
  error!: Object;

  created() {
    this.$titleService.setTitle("Error");
  }
}

import { Action, Module, VuexModule, Mutation } from "vuex-module-decorators";
import * as FpString from "fp-ts/string";
import * as FpMap from "fp-ts/Map";

import { CaseStatusEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import log from "loglevel";
import { QueueFilters } from "~/components/moderation/ModerationFiltersTypes";

// TODO: store original data in state and use getters for transforming it into component friendly stuff.

interface QueueState {
  filters: QueueFilters;
  cursors: Map<string, string>;
}

@Module({
  name: "queue",
  stateFactory: true,
  namespaced: true
})
export default class QueueStore extends VuexModule {
  public queueState: QueueState = {
    filters: {
      categories: [],
      behaviors: [],
      caseStatus: [CaseStatusEnum.PENDINGREVIEW],
      attribute: "",
      languages: [],
      includeUnknownLanguages: false,
      source: []
    },
    cursors: new Map()
  };

  @Mutation
  setFilters(filters: QueueFilters) {
    this.queueState.filters = filters;
    this.queueState.cursors = new Map();
  }

  @Action({ rawError: true })
  updateFilters(filters: QueueFilters) {
    log.info("setting filters to", filters);
    this.context.commit("setFilters", filters);
  }

  @Mutation
  setCursor({ pageKey, cursor }: { pageKey: string; cursor: string }) {
    this.queueState.cursors = FpMap.upsertAt(FpString.Eq)(pageKey, cursor)(this.queueState.cursors);
  }
}

import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";

// applicative instance that will collect errors into arrays and then concatenate them
const errorAp = E.getApplicativeValidation(A.getSemigroup<Error>());
// function that boxes errors into singleton arrays in order to apply the above
const errorToArray = E.mapLeft<Error, Error[]>(A.of);

export function errorTraverse<A, B>(
  updateResultsFn: (results: A[]) => B
): (eInstance: E.Either<Error, A>[]) => E.Either<Error, B> {
  return (eInstance: E.Either<Error, A>[]) =>
    pipe(
      eInstance,
      A.traverse(errorAp)(errorToArray),
      E.bimap(
        (errors) => {
          const errorMessages = errors.map((e) => e.message).join(", ");
          const combinedMessage = `Found errors: ${errorMessages}`;
          return new Error(combinedMessage);
        },
        (results) => updateResultsFn(results)
      )
    );
}


import { pipe } from "fp-ts/lib/function";
import * as Either from "fp-ts/Either";
import Vue from "vue";

export default Vue.extend({
  name: "GrdTopBar",
  data() {
    return {
      userName: pipe(
        this.$authService.user,
        Either.match(
          () => "",
          (user) => user.name
        )
      )
    };
  }
});

import { Plugin } from "@nuxt/types";
import log from "loglevel";
import { Modal, ModalImpl } from "~/plugins/modal/Modal";

declare module "vue/types/vue" {
  interface Vue {
    $modal: Modal;
  }
}

const ModalPlugin: Plugin = (_, inject) => {
  log.trace("starting modal plugin");

  const $modal = new ModalImpl();

  inject("modal", $modal);
};

export default ModalPlugin;

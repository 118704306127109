import { Context } from "@nuxt/types/app";
import log from "loglevel";
import middlewareFactory from "./middlewareFactory";

/**
 * Middleware meant to first page tracking GA events for the nuxtjs/gtm module
 */
log.debug("Running Page Navigation Middleware");
const check = (context: Context) => {
  log.debug("Running Page Navigation Middleware - Checking route: " + context.route.path);
  return context.route.path.startsWith("/user-moderation/");
};

const actionOnFalse = (context: Context) => () => {
  context.$gtm.push({
    event: "nuxtRoute",
    pageType: "PageView",
    routeName: context.route.name,
    pageUrl: context.route.fullPath,
    pageTitle: (typeof document !== "undefined" && document.title) || "",
    customerUUID: context.$authService.isAuthenticated ? context.$authService.clientId : "",
    role: context.$authService.isAuthenticated ? context.$authService.userType : ""
  });
};

const actionOnTrue = (context: Context) => () => {
  context.$gtm.push({
    event: "nuxtRoute",
    pageType: "PageView",
    routeName: context.route.name,
    pageUrl: "/user-moderation/details",
    pageTitle: (typeof document !== "undefined" && document.title) || "",
    customerUUID: context.$authService.isAuthenticated ? context.$authService.clientId : "",
    role: context.$authService.isAuthenticated ? context.$authService.userType : ""
  });
};
const pageTracking = middlewareFactory(check)(actionOnFalse, actionOnTrue);
export default pageTracking;

import log from "loglevel";

import { pipe } from "fp-ts/function";
import * as FpBoolean from "fp-ts/boolean";
import * as TaskEither from "fp-ts/TaskEither";

import { RpcError } from "grpc-web";
import { rpcErrToError } from "./converters";

export const retryAPI = <T>(
  f: () => Promise<T>,
  retryCount: number = 3
): TaskEither.TaskEither<Error, T> => {
  const errorFn = (error: unknown) => rpcErrToError(error as RpcError);
  const tryTaskWithError = TaskEither.tryCatch(f, errorFn);

  return pipe(
    retryTask(tryTaskWithError, retryCount),
    TaskEither.orElse((error) => TaskEither.left<Error, T>(error))
  );
};

export const retryTask = <T>(
  f: TaskEither.TaskEither<Error, T>,
  retryCount: number = 3
): TaskEither.TaskEither<Error, T> => {
  const retryOrError = (error: Error) => {
    return pipe(
      retryCount === 0,
      FpBoolean.match(
        () => {
          log.error(error, `Received error on task attempt, retrying...`);
          return retryTask(f, retryCount - 1);
        },
        () => {
          log.error(error, `Received error on final task retry attempt`);
          return TaskEither.left<Error, T>(error);
        }
      )
    );
  };

  return pipe(f, TaskEither.orElse(retryOrError));
};

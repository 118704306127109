export interface Loader {
  start: () => any;
  finish: () => any;
}

export const withLoading = async <A>(loader: Loader, f: () => Promise<A>) => {
  loader.start();
  const result = await f();
  loader.finish();
  return result;
};

import { Context } from "@nuxt/types/app";
import log from "loglevel";
import middlewareFactory from "./middlewareFactory";

/**
 * middleware meant for aspect level moderation pages.
 * company account must have aspect level moderation turned on.
 */

log.debug("in aspect moderation enabled middleware");
const check = (context: Context) => context.app.$authService.aspectModerationEnabled;
const actionOnFalse = (context: Context) => () => context.redirect("/logout");
const actionOnTrue = () => () => {
  log.debug("aspect moderation enabled middleware passed");
};
const aspectModerationEnabled = middlewareFactory(check)(actionOnFalse, actionOnTrue);

export default aspectModerationEnabled;

import { Auth0Client } from "@auth0/auth0-spa-js";
import log from "loglevel";

import { Plugin } from "@nuxt/types";
import { SpcAuthClient, SpcAuthClientAuth0Impl } from "~/services/spcAuthClient";

let $spcAuthClient: SpcAuthClient;

const auth0Plugin: Plugin = async (context, inject) => {
  log.trace("starting Auth0 Bootstrap plugin");
  const auth0Client = new Auth0Client({
    domain: context.env.auth0Domain as string, // ugly cast from string | undefined.
    client_id: context.env.auth0ClientId as string, // These values are in the nuxt config,
    audience: context.env.auth0Audience,
    scope: context.env.auth0Scopes,
    cacheLocation: "localstorage",
    useRefreshTokens: true
  });

  $spcAuthClient = new SpcAuthClientAuth0Impl(auth0Client);
  inject("spcAuthClient", $spcAuthClient);
  // if we are being redirected from auth0 universal login then we need to do some additional work
  const query = window.location.search;
  if (query.includes("code=") && query.includes("state=")) {
    log.trace("processing auth0 state");
    await auth0Client.handleRedirectCallback();
    window.history.replaceState({}, document.title, "/");
    window.location.replace("/");
  }
};

// exporting for use in Vuex as inject does not work with Vuex module decorators.
function getClient() {
  return {
    $spcAuthClient
  };
}

export { auth0Plugin, getClient };

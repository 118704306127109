
import "reflect-metadata";
import Vue, { PropType } from "vue";
import { Grid, GridOptions } from "ag-grid-community";

export default Vue.extend({
  name: "GrdGrid",
  props: {
    gridOptions: {
      type: Object as PropType<GridOptions>,
      required: true
    }
  },

  data() {
    return { grid: null as Grid | null };
  },

  mounted() {
    this.grid = new Grid(this.$refs.table as HTMLElement, this.gridOptions);
  },

  methods: {
    refreshData() {
      // set the same data source again but this fools the library into refreshing full dataset
      const api = this.gridOptions.api;
      const dataSource = this.gridOptions.datasource;

      if (api && dataSource) {
        api.setDatasource(dataSource);
      }
    }
  }
});

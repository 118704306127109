/**
 * Middleware meant for pages which are meant to work with a user object but which haven't obtained a logged in session yet to access rest of the app.
 * eg Setup MFA which requires a user but he is not supposed to be authenticated.
 */
export default function ({ store, redirect }: { store: any; redirect: any }) {
  if (!store.state.auth.authState.user) {
    redirect("/user/login");
  } else if (store.state.auth.authState.isAuthenticated) {
    redirect("/");
  }
}

import { pipe } from "fp-ts/lib/function";
import * as Str from "fp-ts/lib/string";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as S from "fp-ts/lib/Set";
import {
  AutomationOverrideMessage,
  TriggerModeEnum,
  UpdateCaseStatusRequest
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import {
  CaseStatusEnum,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import {
  WebhookRowWithCases,
  CaseId,
  BulkWebhookInfo,
  CaseRowForWebhook,
  WebhooksToBulkInfo,
  CaseInfo,
  CaseWithOverride,
  overridesArraySemigroup
} from "../models/WebhookModels";
import { webhookInfoToWebhookRow } from "./WebhookModalUtils";

export function bulkRecordsToWebhookRows(
  webhooksToBulkInfo: WebhooksToBulkInfo
): WebhookRowWithCases[] {
  return pipe(
    webhooksToBulkInfo,
    R.toArray,
    A.map(([_, bulkWebhookInfo]) => {
      const { selectedCase, caseInfos } = bulkWebhookInfo;
      const baseWebhookRow = webhookInfoToWebhookRow(bulkWebhookInfo);
      return {
        ...baseWebhookRow,
        caseTable: caseSelectorToCaseTable(selectedCase, caseInfos),
        overrideNotes: bulkWebhookInfo.overrideNotes
      } as WebhookRowWithCases;
    })
  );
}

export function bulkRecordsToStatusRequests(
  webhooksToBulkInfo: WebhooksToBulkInfo,
  createdBy: string
): UpdateCaseStatusRequest[] {
  const selectedRequests = createSelectedAutomationOverrides(webhooksToBulkInfo, createdBy);
  const unselectedRequests = createUnselectedAutomationOverrides(webhooksToBulkInfo, createdBy);
  return pipe(selectedRequests, A.concat(unselectedRequests));
}

function createUnselectedAutomationOverrides(
  webhooksToBulkInfo: WebhooksToBulkInfo,
  createdBy: string
): UpdateCaseStatusRequest[] {
  const selectedCases = pipe(
    webhooksToBulkInfo,
    R.toEntries,
    A.map(([_, b]) => b.selectedCase),
    A.compact,
    S.fromArray(Str.Eq)
  );
  const allAssociatedCases = getAllAssociatedCases(webhooksToBulkInfo);
  const unselectedCases = pipe(allAssociatedCases, S.difference(Str.Eq)(selectedCases));

  return pipe(
    unselectedCases,
    S.toArray(Str.Ord),
    A.map((caseId) =>
      new UpdateCaseStatusRequest()
        .setCaseid(caseId)
        .setSource(FlagSourceEnum.MODERATOR)
        .setNewstatus(CaseStatusEnum.MODERATIONPERFORMED)
        .setCreatedby(createdBy)
    )
  );
}

function createSelectedAutomationOverrides(
  webhooksToBulkInfo: WebhooksToBulkInfo,
  createdBy: string
): UpdateCaseStatusRequest[] {
  const bulkWebhookInfos = pipe(
    webhooksToBulkInfo,
    R.toArray,
    A.map((e) => e[1])
  );

  // create enable override messages for selected cases
  const enabledCasesWithOverrides = pipe(
    bulkWebhookInfos,
    A.map((bulkWebhookInfo) =>
      pipe(
        bulkWebhookInfo.selectedCase,
        O.map((caseId) => {
          const overrideMessage = bulkWebhookInfoToOverride(bulkWebhookInfo);
          return [caseId, overrideMessage] as CaseWithOverride;
        })
      )
    ),
    A.compact
  );

  const selectedCases = pipe(
    enabledCasesWithOverrides,
    A.map((e) => e[0]),
    S.fromArray(Str.Eq)
  );

  // create disable override messages for webhooks on selected cases
  const disabledSelectedOverrides = pipe(
    bulkWebhookInfos,
    A.map(({ selectedCase, caseInfos, subscriptionId }) =>
      pipe(
        selectedCase,
        O.map((selectedCase) =>
          pipe(
            caseInfos,
            A.map((c) => c.caseId),
            S.fromArray(Str.Eq),
            S.remove(Str.Eq)(selectedCase),
            S.intersection(Str.Eq)(selectedCases),
            S.toArray(Str.Ord),
            A.map((caseId) => {
              const overrideMessage = new AutomationOverrideMessage()
                .setSubscriptionid(subscriptionId)
                .setTriggermode(TriggerModeEnum.DISABLE);
              return [caseId, overrideMessage] as CaseWithOverride;
            })
          )
        )
      )
    ),
    A.compact,
    A.flatten
  );

  const allCasesWithOverrides = pipe(
    enabledCasesWithOverrides,
    A.concat(disabledSelectedOverrides)
  );
  const casesToOverrides = R.fromFoldableMap(overridesArraySemigroup, A.Foldable)(
    allCasesWithOverrides,
    ([caseId, overrideMessage]) => [caseId, [overrideMessage]]
  );

  return pipe(
    casesToOverrides,
    R.toArray,
    A.map(([caseId, overridesMessages]) =>
      new UpdateCaseStatusRequest()
        .setCaseid(caseId)
        .setSource(FlagSourceEnum.MODERATOR)
        .setAutomationoverridesList(overridesMessages)
        .setNewstatus(CaseStatusEnum.MODERATIONSCHEDULED)
        .setCreatedby(createdBy)
    )
  );
}

function bulkWebhookInfoToOverride(bulkWebhookInfo: BulkWebhookInfo): AutomationOverrideMessage {
  const { subscriptionId, selected, activeTags, additionalTags, overrideNotes } = bulkWebhookInfo;
  const allTags = pipe(activeTags, S.union(Str.Eq)(additionalTags));
  const overrideMessage = new AutomationOverrideMessage()
    .setSubscriptionid(subscriptionId)
    .setNotes(overrideNotes || "");
  if (selected) {
    return overrideMessage
      .setTriggermode(TriggerModeEnum.NORMAL)
      .setTagoverridesList(pipe(allTags, S.toArray(Str.Ord)));
  } else {
    return overrideMessage.setTriggermode(TriggerModeEnum.DISABLE);
  }
}

function getAllAssociatedCases(webhooksToBulkInfo: WebhooksToBulkInfo): Set<CaseId> {
  return pipe(
    webhooksToBulkInfo,
    R.map((cs) =>
      S.fromArray(Str.Eq)(
        pipe(
          cs.caseInfos,
          A.map((c) => c.caseId)
        )
      )
    ),
    R.toArray,
    A.map((e) => e[1]),
    A.reduce(new Set<CaseId>(), S.union<CaseId>(Str.Eq))
  );
}

function caseSelectorToCaseTable(
  selectedCase: O.Option<CaseId>,
  caseInfos: CaseInfo[]
): CaseRowForWebhook[] {
  return pipe(
    caseInfos,
    A.map(
      ({ caseId, caseLabels, caseText }) =>
        ({
          caseId,
          selected: pipe(
            selectedCase,
            O.exists((c) => Str.Eq.equals(caseId, c))
          ),
          caseLabels,
          caseText
        } as CaseRowForWebhook)
    )
  );
}

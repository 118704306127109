
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { ButtonType } from "@spectrum/pandora";
import { defaultForModalType, ModalDataType, ModalEventType, ModalType } from "~/constants/modals";

@Component
export default class Modal<A extends ModalDataType> extends Vue {
  @Prop({ default: ModalType.DEFAULT }) type!: ModalType;
  @Prop({ default: "36rem" }) maxWidth?: string;

  isOpen = false;
  data: A = defaultForModalType<A>();

  protected confirmButtonType = ButtonType.CONFIRM;

  handleModalClosed() {
    this.$modal.close(this.type);
  }

  created() {
    this.$modal.eventBus.$on(ModalEventType.OPEN, (type: ModalType) => {
      this.isOpen = type === this.type;
    });

    this.$modal.eventBus.$on(ModalEventType.CLOSE, (type: ModalType) => {
      if (this.type !== type) {
        return;
      }

      this.isOpen = false;
    });

    this.$modal.eventBus.$on(ModalEventType.UPDATE, (data: A) => {
      this.data = data;
    });
  }
}

import Vue from "vue";

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/spc-\w*/];

export default (): void => {
  if (!process.client) {
    return;
  }

  import("@spectrum/pandora/loader").then(({ applyPolyfills, defineCustomElements }) => {
    applyPolyfills().then(() => {
      defineCustomElements(window);
    });
  });
};

// libraries we use
import { pipe } from "fp-ts/lib/function";
import * as Str from "fp-ts/lib/string";
import * as A from "fp-ts/lib/Array";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as R from "fp-ts/lib/Record";
import * as S from "fp-ts/lib/Set";
import * as TE from "fp-ts/TaskEither";

import log from "loglevel";

// spectrum grpc libs
import { EventGrpcServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/Event-serviceServiceClientPb";
import {
  AdjustmentTypeEnum,
  AutomationInfoMessage,
  AutomationOverrideMessage,
  BehaviorAdjustmentMessage,
  CaseBehaviorAdjustmentsMessage,
  GetAutomationsRequest,
  TriggerModeEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import {
  CaseStatusEnum,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";

// other modules in Durin
import {
  GetAutomationsInput,
  GetAutomationsOutput,
  CaseId,
  WebhookInfo,
  WebhooksStore,
  WebhookInfoForCase
} from "~/services/webhooks/models/WebhookModels";
import { retryAPI } from "~/utils/retry";

export function createStoreFromCases(
  caseIds: CaseId[],
  source: FlagSourceEnum,
  eventService: EventGrpcServiceClient
): TE.TaskEither<Error, WebhooksStore> {
  log.info("Initializing webhooks store for cases", caseIds);
  return pipe(
    caseIds,
    A.map((caseId) => defaultAutomationsInputFromCase(caseId, source)),
    TE.traverseArray((input) => getAutomationsForCase(eventService, input)),
    TE.map((outputs) => pipe(outputs, RA.toArray, automationsOutputsToWebhookRecord))
  );
}

function createBehaviorAdjustments(
  behaviors: Set<string>,
  source: FlagSourceEnum,
  adjustmentType: AdjustmentTypeEnum
): BehaviorAdjustmentMessage[] {
  return pipe(
    behaviors,
    S.toArray(Str.Ord),
    A.map((b) =>
      new BehaviorAdjustmentMessage()
        .setBehavior(b)
        .setAdjustmenttype(adjustmentType)
        .setSource(source)
    )
  );
}

export function getAutomationsForCase(
  eventService: EventGrpcServiceClient,
  { caseId, source, addedBehaviors, removedBehaviors }: GetAutomationsInput
): TE.TaskEither<Error, GetAutomationsOutput> {
  let getAutomationsRequest = new GetAutomationsRequest()
    .setCaseidsList([caseId])
    .setSource(source)
    .setStatus(CaseStatusEnum.MODERATIONSCHEDULED);

  if (addedBehaviors.size > 0 || removedBehaviors.size > 0) {
    const addedBehaviorMessages = createBehaviorAdjustments(
      addedBehaviors,
      source,
      AdjustmentTypeEnum.ADDED
    );
    const removedBehaviorMessages = createBehaviorAdjustments(
      removedBehaviors,
      source,
      AdjustmentTypeEnum.CLEARED
    );

    const caseAdjustmentMessage = new CaseBehaviorAdjustmentsMessage()
      .setCaseid(caseId)
      .setAdjustmentsList([...addedBehaviorMessages, ...removedBehaviorMessages]);

    getAutomationsRequest = getAutomationsRequest.setAdjustmentsList([caseAdjustmentMessage]);
  }

  log.info("Updating webhooks in store through request", getAutomationsRequest.toObject());
  return pipe(
    retryAPI(() => eventService.getAutomations(getAutomationsRequest, null)),
    TE.map((getAutomationsResponse) => ({ caseId, getAutomationsResponse } as GetAutomationsOutput))
  );
}

export function automationOverridesFromWebhookInfoForCase({
  webhookInfos,
  overrideNotes
}: WebhookInfoForCase): AutomationOverrideMessage[] {
  return pipe(
    webhookInfos,
    A.map((wi) => automationOverrideFromWebhookInfo(wi, overrideNotes || ""))
  );
}

function automationOverrideFromWebhookInfo(
  webhookInfo: WebhookInfo,
  overrideNotes: string
): AutomationOverrideMessage {
  const { subscriptionId, selected, activeTags, additionalTags } = webhookInfo;
  const allTags = pipe(activeTags, S.union(Str.Eq)(additionalTags));
  const triggerMode = selected ? TriggerModeEnum.NORMAL : TriggerModeEnum.DISABLE;
  return new AutomationOverrideMessage()
    .setSubscriptionid(subscriptionId)
    .setTriggermode(triggerMode)
    .setTagoverridesList(pipe(allTags, S.toArray(Str.Ord)))
    .setNotes(overrideNotes);
}

function webhookInfoFromMessage(automationInfoMessage: AutomationInfoMessage): WebhookInfo {
  return {
    subscriptionId: automationInfoMessage.getSubscriptionid(),
    subscriptionName: automationInfoMessage.getSubscriptionname(),
    activeTags: S.fromArray(Str.Eq)(automationInfoMessage.getTagsList()),
    selected: true,
    disabledTags: new Set<string>(),
    additionalTags: new Set<string>()
  };
}

function defaultAutomationsInputFromCase(
  caseId: CaseId,
  source: FlagSourceEnum
): GetAutomationsInput {
  return {
    caseId,
    source,
    addedBehaviors: new Set(),
    removedBehaviors: new Set()
  };
}

export function webhookInfoForCaseFromOutput(
  getAutomationsOutput: GetAutomationsOutput
): WebhookInfo[] {
  log.info("Converting automation output to webhook info array", getAutomationsOutput);
  return pipe(
    getAutomationsOutput.getAutomationsResponse.getCaseautomationsList(),
    A.chain((caseAutomationMessage) => caseAutomationMessage.getAutomationsList()),
    A.map((automationInfoMessage) => webhookInfoFromMessage(automationInfoMessage))
  );
}

function automationsOutputsToWebhookRecord(
  getAutomationsOutputs: GetAutomationsOutput[]
): WebhooksStore {
  return pipe(
    getAutomationsOutputs,
    A.map<GetAutomationsOutput, [CaseId, WebhookInfoForCase]>((getAutomationsOutput) => [
      getAutomationsOutput.caseId,
      { webhookInfos: webhookInfoForCaseFromOutput(getAutomationsOutput) } as WebhookInfoForCase
    ]),
    R.fromEntries
  );
}


import { Lazy, pipe } from "fp-ts/function";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";

import "reflect-metadata";
import log from "loglevel";
import Vue, { PropType } from "vue";
import { ButtonType } from "@spectrum/pandora";
import { SemigroupSum } from "fp-ts/lib/number";
import {
  TagName,
  WebhookEvent,
  WebhookModalData,
  WebhooksStoreUpdate,
  WebhookTagEvent
} from "~/services/webhooks/models/WebhookModels";
import GrdActionsTable from "~/components/override-actions/ActionsTable.vue";
import { ModalType } from "~/constants/modals";
import { errorTraverse } from "~/utils/error";

export default Vue.extend({
  name: "OverrideActions",
  components: { GrdActionsTable },
  props: {
    webhookModalData: { required: true, type: Object as PropType<WebhookModalData> },
    confirmButtonType: { default: ButtonType.CONFIRM, type: String as PropType<ButtonType> }
  },
  data() {
    return {
      overrideNotes: this.webhookModalData.overrideNotes,
      showActionsDisplayError: false,
      showDisplayWebhookError: false,
      actionsDisplayErrorMessage: "Cannot display actions for case, please try again later!",
      undoActionsQueue: [] as Lazy<WebhooksStoreUpdate>[]
    };
  },
  computed: {
    isLoading() {
      const loader = this.$nuxt.$loading;
      // N.B. the syntax below attempts to narrow down to DefaultNuxtLoading to use the show method
      return "show" in loader && loader.show;
    },
    caseId() {
      return this.webhookModalData.caseId;
    },
    allTagsForClient() {
      return this.webhookModalData.allTagsForClient;
    },
    webhookRows() {
      return this.$webhooksService.getAllWebhooksForCase(this.webhookModalData.caseId);
    }
  },
  watch: {
    webhookModalData() {
      this.overrideNotes = this.webhookModalData.overrideNotes;
    }
  },
  methods: {
    handleToggleTag(webhookTagEvent: WebhookTagEvent) {
      const { tagName, subscriptionName, subscriptionId } = webhookTagEvent;
      pipe(
        this.$webhooksService.toggleTagForWebhook(this.caseId, subscriptionId, tagName),
        E.fold(
          (e) => {
            log.error(e, `Received error when toggling tag on action for [case: ${this.caseId}]`);
            this.showActionsDisplayError = true;
          },
          () => {
            log.info(`Toggled tag on action for [webhook: ${subscriptionName}, tag: ${tagName}]`);
            this.showActionsDisplayError = false;
            this.undoActionsQueue = pipe(
              this.undoActionsQueue,
              A.append(() =>
                this.$webhooksService.toggleTagForWebhook(this.caseId, subscriptionId, tagName)
              )
            );
          }
        )
      );
    },
    handleAddTag(webhookTagEvent: WebhookTagEvent) {
      const { tagName, subscriptionName, subscriptionId } = webhookTagEvent;
      pipe(
        this.$webhooksService.addTagsForWebhook(
          this.caseId,
          subscriptionId,
          new Set<TagName>([tagName])
        ),
        E.fold(
          (e) => {
            log.error(e, `Received error when adding new tag for [case: ${this.caseId}]`);
            this.showActionsDisplayError = true;
          },
          () => {
            log.info(`Added new tag to [webhook: ${subscriptionName}, tag: ${tagName}]`);
            this.showActionsDisplayError = false;
            this.webhookRows = this.$webhooksService.getAllWebhooksForCase(
              this.webhookModalData.caseId
            );
          }
        )
      );
    },
    handleToggleAction(webhookEvent: WebhookEvent) {
      const { subscriptionName, subscriptionId } = webhookEvent;
      pipe(
        this.$webhooksService.toggleWebhook(this.caseId, subscriptionId),
        E.fold(
          (e) => {
            log.error(e, `Received error when toggling action for [case: ${this.caseId}]`);
            this.showActionsDisplayError = true;
          },
          () => {
            log.info(`Toggled action for [webhook: ${subscriptionName}]`);
            this.showActionsDisplayError = false;
            this.undoActionsQueue = pipe(
              this.undoActionsQueue,
              A.append(() => this.$webhooksService.toggleWebhook(this.caseId, subscriptionId))
            );
          }
        )
      );
    },
    handleClickCancel() {
      // Gather updates to remove all additionally added tags per webhook
      const removeAddedTagsForAllWebhooks = pipe(
        this.webhookRows,
        A.map(
          (w) => () =>
            this.$webhooksService.removeAddedTagsForWebhook(this.caseId, w.subscriptionId)
        )
      );
      pipe(
        // Concat the additional tag removals with the existing undo actions queue
        pipe(this.undoActionsQueue, A.concat(removeAddedTagsForAllWebhooks)),
        // Apply all undo actions
        A.reduce<Lazy<WebhooksStoreUpdate>, WebhooksStoreUpdate[]>(
          [],
          (appliedUpdates: WebhooksStoreUpdate[], update: Lazy<WebhooksStoreUpdate>) =>
            pipe(appliedUpdates, A.append(update()))
        ),
        // Traverse and father all errors from the undid actions
        errorTraverse<number, number>((removeTagsResults) =>
          pipe(removeTagsResults, A.reduce(0, SemigroupSum.concat))
        ),
        E.fold(
          (e) => {
            log.error(e, `Received error(s) undoing actions for [case: ${this.caseId}]`);
            this.showActionsDisplayError = true;
          },
          (n) => {
            log.info(`Undid ${n} actions within modal for [case: ${this.caseId}]`);
            this.showActionsDisplayError = false;
            this.undoActionsQueue = [] as Lazy<WebhooksStoreUpdate>[];
            this.overrideNotes = "";
          }
        )
      );
      this.$modal.close(ModalType.OVERRIDE);
    },
    handleSave() {
      pipe(
        this.$webhooksService.addNotesForOverride(this.caseId, this.overrideNotes),
        E.fold(
          (e) => {
            log.error(e, `Received error(s) updating actions for [case: ${this.caseId}]`);
            this.showActionsDisplayError = true;
          },
          (n) => {
            log.info(`Applied ${n} updates to actions for [case: ${this.caseId}]`);
            this.showActionsDisplayError = false;
          }
        )
      );
      this.undoActionsQueue = [] as Lazy<WebhooksStoreUpdate>[];
      this.overrideNotes = "";

      if (this.webhookModalData.confirmFn) {
        log.info(`Saving action info and confirming for [case: ${this.caseId}]`);
        this.webhookModalData.confirmFn();
      }

      this.$modal.close(ModalType.OVERRIDE);
    }
  }
});

/**
 * This module initializes all the stores so that they can be imported in other components in a typesafe manner.
 *
 * We achieve this via two tools
 * 1.  vuex-module-decorators when defining the store modules
 * 2.  A vuex plugin (authStoreInitializer defined below).
 *
 * The plugin is invoked when the store is initialized, at which point getModule from vuex-module-decorators is used to instantiate a typesafe reference to the store.
 *
 * Vuex-module decorator -
 *   https://championswimmer.in/vuex-module-decorators/
 *   https://github.com/championswimmer/vuex-module-decorators#accessing-modules-with-nuxtjs
 * Vuex plugins -
 *   https://vuex.vuejs.org/guide/plugins.html
 */
import { Store } from "vuex";
import { initializeAuthStore, getAuthStore } from "~/utils/storeAccessors/authStoreAccessor";
import { initializeQueueStore, getQueueStore } from "~/utils/storeAccessors/queueStoreAccessor";
import {
  initializeUserQueueStore,
  getUserQueueStore
} from "~/utils/storeAccessors/userQueueStoreAccessor";

const authStoreInitializer = (store: Store<any>) => initializeAuthStore(store);
const queueStoreInitializer = (store: Store<any>) => initializeQueueStore(store);
const userQueueStoreInitializer = (store: Store<any>) => initializeUserQueueStore(store);

export const plugins = [authStoreInitializer, queueStoreInitializer, userQueueStoreInitializer];

export { getQueueStore, getAuthStore, getUserQueueStore };

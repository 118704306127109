import { Plugin } from "@nuxt/types";
import { pipe } from "fp-ts/function";
import * as FpBoolean from "fp-ts/boolean";
import { auth0Plugin, getClient as getRealClient } from "~/plugins/auth0/auth0Plugin";
import { auth0MockPlugin, getMockClient } from "~/plugins/auth0/auth0MockPlugin";

import { SpcAuthClient } from "~/services/spcAuthClient";

declare module "vue/types/vue" {
  interface Vue {
    $spcAuthClient: SpcAuthClient;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$auth0Client inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $spcAuthClient: SpcAuthClient;
  }
  // nuxtContext.$auth0Client
  interface Context {
    $spcAuthClient: SpcAuthClient;
  }
}

let clientGetter: () => { $spcAuthClient: SpcAuthClient };

/*
 * We have two plugins for auth0: one for production use, one for e2e tests which does not require a redirect to auth0
 * This plugin determines which one to use depending on context.env.e2eTestMode === true
 */
const auth0BootstrapPlugin: Plugin = (context, inject) => {
  clientGetter = pipe(
    context.env.e2eTestMode === true,
    FpBoolean.fold(
      () => getRealClient,
      () => getMockClient
    )
  );
  return pipe(
    context.env.e2eTestMode === true,
    FpBoolean.fold(
      () => auth0Plugin(context, inject),
      () => auth0MockPlugin(context, inject)
    )
  );
};

export default auth0BootstrapPlugin;

// TODO: this getClient can be made redundant if we move business logic to service from vuex states
export function getClient() {
  return clientGetter();
}

export class PageTitleService {
  private pageTitle: string = "";

  public setTitle(title: string) {
    this.pageTitle = title;
  }

  get title() {
    return this.pageTitle;
  }
}

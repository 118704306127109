
import "reflect-metadata";
import Vue, { PropType } from "vue";
import log from "loglevel";
import * as FpArray from "fp-ts/Array";
import * as Option from "fp-ts/Option";
import { flow, pipe } from "fp-ts/lib/function";
import { Colors } from "@spectrum/pandora";
import {
  WebhookInfo,
  WebhookEvent,
  WebhookTagEvent,
  WebhookTag
} from "~/services/webhooks/models/WebhookModels";
import { webhookInfoToWebhookRow } from "~/services/webhooks/utils/WebhookModalUtils";

export default Vue.extend({
  name: "GrdActionsTable",
  props: {
    allTagsForClient: {
      default: (): WebhookTag[] => [] as WebhookTag[],
      type: Array as PropType<WebhookTag[]>
    },
    webhookInfos: {
      default: (): WebhookInfo[] => [],
      type: Array as PropType<WebhookInfo[]>
    },
    displayOnly: { default: false, type: Boolean }
  },
  data() {
    return {
      activeWebhookToggleColor: Colors.greenDeep,
      activeTagToggleColor: Colors.green,
      disabledTagToggleColor: Colors.greyLight
    };
  },
  computed: {
    webhookRows() {
      return pipe(
        this.webhookInfos,
        Option.fromNullable,
        Option.match(() => [], flow(FpArray.map(webhookInfoToWebhookRow)))
      );
    }
  },
  methods: {
    handleToggleTag(webhookTagEvent: WebhookTagEvent) {
      this.$emit("toggleTag", webhookTagEvent);
    },
    handleAddTag(webhookTagEvent: WebhookTagEvent) {
      this.$emit("addTag", webhookTagEvent);
    },
    handleToggleWebhook(webhookEvent: WebhookEvent) {
      log.info("emitting 'toggleWebhook' for event", webhookEvent);
      this.$emit("toggleWebhook", webhookEvent);
    }
  }
});

const middleware = {}

middleware['aspectModerationEnabled'] = require('../middleware/aspectModerationEnabled.ts')
middleware['aspectModerationEnabled'] = middleware['aspectModerationEnabled'].default || middleware['aspectModerationEnabled']

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['listManagementEnabled'] = require('../middleware/listManagementEnabled.ts')
middleware['listManagementEnabled'] = middleware['listManagementEnabled'].default || middleware['listManagementEnabled']

middleware['middlewareFactory'] = require('../middleware/middlewareFactory.ts')
middleware['middlewareFactory'] = middleware['middlewareFactory'].default || middleware['middlewareFactory']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.ts')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['notAuthenticatedWithUser'] = require('../middleware/notAuthenticatedWithUser.ts')
middleware['notAuthenticatedWithUser'] = middleware['notAuthenticatedWithUser'].default || middleware['notAuthenticatedWithUser']

middleware['pageTracking'] = require('../middleware/pageTracking.ts')
middleware['pageTracking'] = middleware['pageTracking'].default || middleware['pageTracking']

export default middleware

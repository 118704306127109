
import "reflect-metadata";
import Vue, { PropType } from "vue";
import log from "loglevel";
import { Colors } from "@spectrum/pandora";
import {
  WebhookEvent,
  WebhookTagEvent,
  WebhookTag,
  WebhookRowWithCases,
  WebhooksToBulkInfo
} from "~/services/webhooks/models/WebhookModels";
import { bulkRecordsToWebhookRows } from "~/services/webhooks/utils/BulkWebhookModalUtils";
import BulkCaseTable from "~/components/override-actions/bulk-level/BulkCaseTable.vue";

export default Vue.extend({
  name: "BulkActionsTable",
  components: { BulkCaseTable },

  props: {
    allTagsForClient: {
      default: (): WebhookTag[] => [] as WebhookTag[],
      type: Array as PropType<WebhookTag[]>
    },
    webhooksToBulkInfo: {
      default: (): WebhooksToBulkInfo => ({} as WebhooksToBulkInfo),
      type: Object as PropType<WebhooksToBulkInfo>
    }
  },
  data() {
    return {
      activeWebhookToggleColor: Colors.greenDeep,
      activeTagToggleColor: Colors.green,
      disabledTagToggleColor: Colors.greyLight,
      displayWebhookRowsError: false
    };
  },
  computed: {
    webhookRows(): WebhookRowWithCases[] {
      return bulkRecordsToWebhookRows(this.webhooksToBulkInfo);
    }
  },
  methods: {
    handleToggleTag(webhookTagEvent: WebhookTagEvent) {
      this.$emit("toggleTag", webhookTagEvent);
    },
    handleAddTag(webhookTagEvent: WebhookTagEvent) {
      this.$emit("addTag", webhookTagEvent);
    },
    handleToggleWebhook(webhookEvent: WebhookEvent) {
      log.info("emitting 'toggleWebhook' for event", webhookEvent);
      this.$emit("toggleWebhook", webhookEvent);
    }
  }
});

import { RpcError, StatusCode } from "grpc-web";
import { UtcTimestamp } from "@spectrum/grpc-protobuf-client-js/getspectrum/common/common_pb";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";
import { dateFormat } from "~/utils/formatters";

export const rpcErrToError = (err: RpcError) => {
  const e = new Error(
    `${err.name}: Code ${err.code} - ${
      Object.keys(StatusCode)[err.code + 1] ?? `Code Undefined for ${err.code}`
    }: ${err.message}`
  );
  // Preserve the current and underlying call stack
  e.stack = `${e.stack}\nCaused by ${err.stack}`;
  return e;
};

export const convertTimestampToDate = (timestamp: UtcTimestamp | undefined) =>
  pipe(
    timestamp,
    O.fromNullable,
    O.match(
      () => "",
      (t) => dateFormat.format(t.getEpochmillis())
    )
  );

import { FlagSourceEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import { pipe } from "fp-ts/function";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import * as Eq from "fp-ts/lib/Eq";
import * as Mag from "fp-ts/lib/Magma";
import * as Ord from "fp-ts/lib/Ord";
import * as Str from "fp-ts/lib/string";
import {
  AutomationOverrideMessage,
  GetAutomationsResponse
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import { Semigroup } from "fp-ts/lib/Semigroup";

// TYPE ALIASES //

export type CaseId = string;
export type UserId = string;
export type WebhooksStoreUpdate = E.Either<Error, number>;
export type SubscriptionId = string;
export type SubscriptionName = string;
export type TagName = string;

// SERVICE INTERFACES //

export interface GetAutomationsInput {
  caseId: CaseId;
  source: FlagSourceEnum;
  addedBehaviors: Set<string>;
  removedBehaviors: Set<string>;
}

export interface GetAutomationsOutput {
  caseId: CaseId;
  getAutomationsResponse: GetAutomationsResponse;
}

export interface WebhookInfo {
  // information returned through API
  subscriptionId: SubscriptionId;
  subscriptionName: SubscriptionName;
  activeTags: Set<TagName>;

  // information modified by user
  selected: boolean;
  disabledTags: Set<TagName>;
  additionalTags: Set<TagName>;
}

export interface WebhookInfoForCase {
  webhookInfos: WebhookInfo[];
  overrideNotes?: string;
}

export type WebhooksStore = Record<CaseId, WebhookInfoForCase>;

export interface WebhookTag {
  displayName: string;
  tagName: TagName;
  enabled: boolean;
}

// EVENT INTERFACES //

export interface WebhookEvent {
  subscriptionName: SubscriptionName;
  subscriptionId: SubscriptionId;
}

export interface WebhookTagEvent extends WebhookEvent {
  tagName: TagName;
}

export interface WebhookCaseEvent extends WebhookEvent {
  caseId: CaseId;
}

// MODAL INTERFACES //

export interface BaseWebhookModalData {
  allTagsForClient: WebhookTag[];
  overrideNotes: string;
}

export interface WebhookModalData extends BaseWebhookModalData {
  caseId: CaseId;
  confirmFn?: () => void;
}

export interface WebhookRow {
  displayName: string;
  subscriptionName: SubscriptionName;
  subscriptionId: SubscriptionId;
  enabled: boolean;
  webhookTags: WebhookTag[];
}

// BULK MODAL INTERFACES

export interface CaseInfo {
  caseId: CaseId;
  caseLabels: string[];
  caseText: string;
}

export interface BulkWebhookInfo extends WebhookInfo {
  overrideNotes?: string;
  caseInfos: CaseInfo[];
  selectedCase: O.Option<CaseId>;
}

export interface CaseRowForWebhook {
  caseId: CaseId;
  selected: boolean;
  caseLabels: string[];
  caseText: string;
}

export interface WebhookRowWithCases extends WebhookRow {
  caseTable: CaseRowForWebhook[];
  overrideNotes: string;
}

export interface WebhookAndCaseInfos {
  webhookInfo: WebhookInfo;
  caseInfos: CaseInfo[];
}

export type CaseWithOverride = [CaseId, AutomationOverrideMessage];
// export interface CaseWithOverride {
//   caseId: CaseId;
//   overrideMessage: AutomationOverrideMessage;
// }

export type WebhooksAndInfo = [SubscriptionId, WebhookAndCaseInfos];
export type WebhooksToBulkInfo = Record<SubscriptionId, BulkWebhookInfo>;
export type CasesToWebhooks = Record<CaseId, SubscriptionId[]>;

export interface WebhookBulkModalData extends BaseWebhookModalData {
  userId: UserId;
  webhooksToBulkInfo: WebhooksToBulkInfo;
  refreshFn: () => Promise<void>;
}

// DEFAULT VALUES //

export const defaultInfoForCase = { webhookInfos: [] } as WebhookInfoForCase;

// FP-TS SINGLETONS //

export const webhookInfoEq: Eq.Eq<WebhookInfo> = pipe(
  Str.Eq,
  Eq.contramap((w: WebhookInfo) => w.subscriptionId)
);

export const webhookInfoForCaseMagma: Mag.Magma<WebhookInfoForCase> = {
  concat: (a: WebhookInfoForCase, b: WebhookInfoForCase) => {
    return {
      webhookInfos: pipe(a.webhookInfos, A.union(webhookInfoEq)(b.webhookInfos)),
      overrideNotes: [a.overrideNotes, b.overrideNotes].join("\n\n").trim()
    } as WebhookInfoForCase;
  }
};

export const webhookAndCaseInfoSemigroup: Semigroup<WebhookAndCaseInfos> = {
  concat: (a: WebhookAndCaseInfos, b: WebhookAndCaseInfos) => {
    return {
      webhookInfo: a.webhookInfo,
      caseInfos: pipe(a.caseInfos, A.concat(b.caseInfos))
    } as WebhookAndCaseInfos;
  }
};

export const overridesArraySemigroup: Semigroup<AutomationOverrideMessage[]> = {
  concat: (a: AutomationOverrideMessage[], b: AutomationOverrideMessage[]) => {
    return pipe(a, A.concat(b));
  }
};

export const stringArraySemigroup: Semigroup<string[]> = {
  concat: (a: string[], b: string[]) => {
    const aAsSet = S.fromArray(Str.Eq)(a);
    const bAsSet = S.fromArray(Str.Eq)(b);
    return pipe(aAsSet, S.union(Str.Eq)(bAsSet), S.toArray(Str.Ord));
  }
};

export const webhookTagEq: Eq.Eq<WebhookTag> = pipe(
  Str.Eq,
  Eq.contramap((w: WebhookTag) => w.tagName)
);

export const webhookTagOrd: Ord.Ord<WebhookTag> = pipe(
  Str.Ord,
  Ord.contramap((w: WebhookTag) => w.tagName)
);

// ERROR CLASSES //

export class WebhookNotFoundForCaseError extends Error {
  constructor(caseId: CaseId) {
    const errorMessage = `Cannot find webhook info for case: ${caseId}`;
    super(errorMessage);
    this.name = "WebhookNotFoundForCaseError";
  }
}

export class WebhookNotFoundForSubscriptionError extends Error {
  constructor(subscriptionName: SubscriptionName) {
    const errorMessage = `Cannot find webhook info for subscription: ${subscriptionName}`;
    super(errorMessage);
    this.name = "WebhookNotFoundForSubscriptionError";
  }
}

export class CaseSelectorNotFoundError extends Error {
  constructor(subscriptionId: SubscriptionId) {
    const errorMessage = `No associated cases were found for [subscription: ${subscriptionId}]`;
    super(errorMessage);
    this.name = "CaseSelectorNotFoundError";
  }
}

export class NoCaseSelectedError extends Error {
  constructor(subscriptionId: SubscriptionId) {
    const errorMessage = `No case was selected for [subscription: ${subscriptionId}]`;
    super(errorMessage);
    this.name = "NoCaseSelectedError";
  }
}

import { CaseStatusEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";

type CaseStatusDisplayMap = { [caseStatus in CaseStatusEnum]: string };

export const CASE_STATUS_DISPLAY_MAP: CaseStatusDisplayMap = {
  [CaseStatusEnum.AUTOMATIONCOMPLETED]: "automation completed",
  [CaseStatusEnum.AUTOMATIONFAILED]: "automation failed",
  [CaseStatusEnum.AUTOMATIONSCHEDULED]: "automation scheduled",
  [CaseStatusEnum.CLEAREDBYMODERATOR]: "cleared by moderator",
  [CaseStatusEnum.ESCALATED]: "escalated",
  [CaseStatusEnum.MODERATIONPERFORMED]: "moderation performed",
  [CaseStatusEnum.MODERATIONSCHEDULED]: "moderation scheduled",
  [CaseStatusEnum.MUTED]: "muted",
  [CaseStatusEnum.PENDINGREVIEW]: "pending review",
  [CaseStatusEnum.VIEWEND]: "view end",
  [CaseStatusEnum.VIEWSTART]: "view start"
};

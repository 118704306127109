import { pipe } from "fp-ts/function";
import * as FpSet from "fp-ts/Set";
import * as Eq from "fp-ts/Eq";
import * as Number from "fp-ts/number";
import * as String from "fp-ts/string";
import * as FpArray from "fp-ts/Array";
import * as Option from "fp-ts/Option";
import * as Either from "fp-ts/Either";
import * as Task from "fp-ts/Task";

import log from "loglevel";

import {
  CompanyMessage,
  LoginMessage,
  PermissionsEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/common/moria_common_pb";

import {
  CaseStatusEnum,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import { attemptedLoginKey, successfulLoginKey } from "~/constants/login";
import AuthStore from "~/store/auth";

export interface SpcUser {
  name: string;
  email: string;
}

export interface AuthService {
  isAuthenticated: boolean;
  categories: Array<string>;
  company: Option.Option<CompanyMessage.AsObject>;
  companyOrLogout: () => CompanyMessage.AsObject;
  defaultSelectedBehaviors: Array<string>;
  allBehaviors: Array<string>;
  allLanguages: Array<string>;
  allCaseStatuses: Array<CaseStatusEnum>;
  allSources: Array<FlagSourceEnum>;
  userDefaultLanguages: Array<string>;
  user: Either.Either<Error, SpcUser>;
  userOrLogout: () => SpcUser;
  loginAndSaveClient: () => Promise<void>;
  logout: () => void;
  permissions: Set<PermissionsEnum>;
  clientId: string;
  userType: string;
  aspectModerationEnabled: boolean;
  behaviorAnalyticsEnabled: boolean;
  userAdministrationEnabled: boolean;
  viewListsEnabled: boolean;
}

export class AuthServiceImpl implements AuthService {
  private $store: AuthStore;

  constructor(store: AuthStore) {
    this.$store = store;
  }

  public loginAndSaveClient() {
    // side effect function to keep track of last succesful client that was used for login
    // attemptedLoginKey is set by the login/_client.vue page, look it up and save the value under successfulLoginKey
    const updateLastLogin = () => {
      pipe(
        localStorage.getItem(attemptedLoginKey),
        Option.fromNullable,
        Option.map((lastLoginClient) => localStorage.setItem(successfulLoginKey, lastLoginClient))
      );
    };

    // side effect function that processes the loginResult
    const processResult = (loginResult: Either.Either<Error, LoginMessage.AsObject>) => {
      log.info("login result is", loginResult);
      const msg = pipe(
        loginResult,
        Either.fold(
          (error) => `AuthService plugin: Error logging in ${error}`,
          (loginResult) => {
            updateLastLogin();
            return `AuthService plugin: Login successful. ${loginResult.company?.clientid}`;
          }
        )
      );
      log.debug(msg);
    };

    return pipe(() => this.$store.login(), Task.map(processResult))();
  }

  public logout() {
    this.$store.logout();
  }

  get isAuthenticated() {
    return this.$store.authState.isAuthenticated;
  }

  get permissions() {
    const eqEnum = Eq.fromEquals((x: PermissionsEnum, y: PermissionsEnum) => {
      return Number.Eq.equals(x, y);
    });
    return pipe(
      this.$store.authState.permissions,
      Option.getOrElseW(() => {
        throw new Error("Cannot proceed. User has no permissions");
      }),
      FpSet.fromArray(eqEnum)
    );
  }

  get aspectModerationEnabled() {
    const aspectModerationEnabled = pipe(
      this.company,
      Option.match(
        () => false,
        (company) => company.aspectmoderationenabled
      )
    );
    return aspectModerationEnabled && this.permissions.has(PermissionsEnum.MODERATEASPECTS);
  }

  get behaviorAnalyticsEnabled() {
    return this.permissions.has(PermissionsEnum.VIEWBEHAVIOR);
  }

  get userAdministrationEnabled() {
    return this.permissions.has(PermissionsEnum.MANAGEUSERS);
  }

  get viewListsEnabled() {
    return this.permissions.has(PermissionsEnum.VIEWLISTS);
  }

  get user() {
    return pipe(
      this.$store.authState.user,
      Either.fromOption(() => new Error("Cannot find the user. Try logging in.")),
      Either.chain((user) => {
        return pipe(
          user.email,
          Either.fromNullable(new Error("User does not have email. Try logging in")),
          Either.map((email) => {
            return { name: user.name || email, email };
          })
        );
      })
    );
  }

  public userOrLogout() {
    return pipe(
      this.user,
      Either.fold(
        (e) => {
          this.logout();
          throw e;
        },
        (user) => user
      )
    );
  }

  get userDefaultLanguages() {
    return pipe(
      this.$store.authState.user,
      Option.map((user) => user["https://app.getspectrum.io/default_languages"]),
      Option.getOrElse(() => [] as string[])
    );
  }

  get clientId() {
    return pipe(
      this.$store.authState.clientDetails,
      Option.map((client) => client.clientid),
      Option.fold(
        () => "",
        (value) => value?.toString()
      )
    );
  }

  get userType() {
    return this.userOrLogout().email.includes("getspectrum.io") ? "internal" : "external";
  }

  get company() {
    return this.$store.authState.clientDetails;
  }

  public companyOrLogout() {
    return pipe(
      this.company,
      Option.fold(
        () => {
          this.logout();
          throw new Error("Company not found. Logging out.");
        },
        (company) => company
      )
    );
  }

  get categories() {
    return this.$store.categories;
  }

  get defaultSelectedBehaviors() {
    return pipe(
      this.$store.authState.clientDetails,
      Option.map((client) =>
        client.defaultselectedbehaviorsList.map((x) => x.selectedbehaviorsList)
      ),
      Option.map(FpArray.flatten),
      Option.getOrElse(() => [] as Array<string>)
    );
  }

  get allLanguages() {
    return pipe(
      this.company,
      Option.match(
        (): string[] => [],
        (company) => company.languagesList
      )
    );
  }

  get allBehaviors() {
    return pipe(
      this.$store.authState.clientDetails,
      Option.map((client) => client.behaviorsList.map((b) => b.behavior)),
      Option.getOrElse(() => [] as Array<string>),
      FpArray.uniq(String.Eq)
    );
  }

  get allCaseStatuses() {
    return [
      CaseStatusEnum.AUTOMATIONCOMPLETED,
      CaseStatusEnum.AUTOMATIONFAILED,
      CaseStatusEnum.AUTOMATIONSCHEDULED,
      CaseStatusEnum.CLEAREDBYMODERATOR,
      CaseStatusEnum.MODERATIONSCHEDULED,
      CaseStatusEnum.MODERATIONPERFORMED,
      CaseStatusEnum.MUTED,
      CaseStatusEnum.PENDINGREVIEW
    ];
  }

  get allSources() {
    return [FlagSourceEnum.SPECTRUM, FlagSourceEnum.USER];
  }
}

import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import QueueStore from "~/store/queue";

let queueStore: QueueStore;

export const initializeQueueStore = (store: Store<any>): void => {
  queueStore = getModule(QueueStore, store);
};

export const getQueueStore = (): QueueStore => {
  return queueStore;
};

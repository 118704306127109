
import Vue from "vue";
import Modal from "~/components/modal/index.vue";
import OverrideActions from "~/components/override-actions/OverrideActions.vue";
import BulkOverrideActions from "~/components/override-actions/bulk-level/BulkOverrideActions.vue";
import { ModalType } from "~/constants/modals";

export default Vue.extend({
  name: "ModalContainer",
  components: {
    Modal,
    OverrideActions,
    BulkOverrideActions
  },
  data() {
    return { modalType: ModalType };
  }
});

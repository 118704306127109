import { pipe } from "fp-ts/function";
import * as FpArray from "fp-ts/lib/Array";
import * as FpEq from "fp-ts/lib/Eq";
import * as FpMagma from "fp-ts/lib/Magma";
import * as FpOrd from "fp-ts/lib/Ord";
import * as FpSet from "fp-ts/lib/Set";
import * as FpString from "fp-ts/string";

import {
  AutomationOverrideMessage,
  BehaviorAdjustmentMessage,
  CollectionIdMessage
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import {
  CaseStatusEnum,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import { ConfidenceBucketEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/classify/models_pb";
import { ModalDataType } from "~/constants/modals";

export interface DeepLinkedAttributes {
  attributeKey: string;
  linkTemplate: string;
  displayText?: string;
}

export enum Priority {
  SEVERE = "SEVERE",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  DEFAULT = "DEFAULT"
}

export interface CaseModerationEventArgs {
  added?: string[];
  removed?: string[];
  notes: string;
}

export interface LabelDisplay {
  displayName: string;
  behavior: string;
  displayColor: string;
}

export const labelDisplayOrd: FpOrd.Ord<LabelDisplay> = pipe(
  FpString.Ord,
  FpOrd.contramap((r: LabelDisplay) => r.displayName)
);

export interface LabelInformation {
  behavior: string;
  confidence?: ConfidenceBucketEnum;
  source?: FlagSourceEnum;
  original: boolean;
}

export const getLabelNamesSet = FpSet.map(FpString.Eq)((l: LabelInformation) => l.behavior);
export const getLabelNamesArray = FpArray.map((l: LabelInformation) => l.behavior);

export const labelInformationOrd: FpOrd.Ord<LabelInformation> = pipe(
  FpString.Ord,
  FpOrd.contramap((r: LabelInformation) => r.behavior)
);

export const labelInformationEq: FpEq.Eq<LabelInformation> = pipe(
  FpString.Eq,
  FpEq.contramap((r: LabelInformation) => r.behavior)
);

export const labelInformationEqStrict: FpEq.Eq<LabelInformation> = FpEq.fromEquals(
  (a, b) =>
    a.behavior === b.behavior &&
    a.confidence === b.confidence &&
    a.source === b.source &&
    a.original === b.original
);

export const labelInformationSetMagma: FpMagma.Magma<Set<LabelInformation>> = {
  concat: (a: Set<LabelInformation>, b: Set<LabelInformation>) =>
    FpSet.union(labelInformationEqStrict)(a, b)
};

export interface UpdateCaseStatusArgs {
  caseId: string;
  caseStatus: CaseStatusEnum;
  automationOverrides: AutomationOverrideMessage[];
  added?: string[];
  removed?: string[];
  notes: string;
}

export interface CaseIndexable {
  caseId: string;
}

export interface ModerationDataRow extends CaseIndexable, ModalDataType {
  category?: string;
  contents: string;
  collectionIdsList: Array<CollectionIdMessage.AsObject>;
  priority: Priority;
  source?: string;
  sourceUserReport: boolean;
  status?: CaseStatusEnum;
  labels: Array<LabelInformation>;
  addedLabels: Array<LabelInformation>;
  allLabels: Array<string>;
  removedLabels: Array<LabelInformation>;
  userLabels: Array<LabelInformation>;
  userNotes: string;
  language?: string;
  labelAdjustments: Array<BehaviorAdjustmentMessage.AsObject>;
  metadata: { [key: string]: string };
  deepLinkedAttributes?: DeepLinkedAttributes;
  epochMillis?: number;
  weight: number;
}

export interface ModerationApiResult {
  messages: ModerationDataRow[];
  cursor: string;
}


import "reflect-metadata";
import Vue from "vue";
import type { PropType } from "vue";

export default Vue.extend({
  name: "GrdGridHeader",
  props: {
    gridHeaders: {
      type: Array as PropType<string[]>,
      required: true
    },
    columnTemplate: {
      type: String,
      default: "none"
    }
  }
});

import { Context } from "@nuxt/types/app";
import log from "loglevel";
import middlewareFactory from "./middlewareFactory";

/**
 * middleware meant for list management pages.
 * user account must have the viewLists permission.
 */

log.debug("in list management enabled middleware");
const check = (context: Context) => context.app.$authService.viewListsEnabled;
const actionOnFalse = (context: Context) => () => context.redirect("/");
const actionOnTrue = () => () => {
  log.debug("aspect moderation enabled middleware passed");
};
const aspectModerationEnabled = middlewareFactory(check)(actionOnFalse, actionOnTrue);

export default aspectModerationEnabled;

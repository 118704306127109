// libraries we use
import { identity, pipe } from "fp-ts/lib/function";
import * as Str from "fp-ts/lib/string";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import * as S from "fp-ts/lib/Set";
import * as TE from "fp-ts/TaskEither";

import log from "loglevel";

// spectrum grpc libs
// eslint-disable-next-line
import { EventGrpcServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/Event-serviceServiceClientPb";
import {
  AutomationOverrideMessage,
  BatchCaseUpdateRequest,
  MultiUserContentMessage,
  UpdateCaseStatusRequest
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";

// other modules in Durin
import { RpcError } from "grpc-web";
import {
  ClientMessage,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import { AuthService } from "../authService";
import {
  BulkWebhookInfo,
  CaseId,
  defaultInfoForCase,
  GetAutomationsInput,
  webhookAndCaseInfoSemigroup,
  SubscriptionId,
  TagName,
  WebhookInfo,
  WebhookInfoForCase,
  webhookInfoForCaseMagma,
  WebhookNotFoundForCaseError,
  WebhooksStore,
  WebhooksStoreUpdate,
  WebhooksToBulkInfo,
  WebhooksAndInfo,
  CaseInfo
} from "~/services/webhooks/models/WebhookModels";
import {
  webhookInfoForCaseFromOutput,
  automationOverridesFromWebhookInfoForCase,
  createStoreFromCases,
  getAutomationsForCase
} from "~/services/webhooks/utils/WebhooksServiceUtils";
import { getServices } from "~/plugins/grpcServices";
import { rpcErrToError } from "~/utils";
import { errorTraverse } from "~/utils/error";
import { retryAPI } from "~/utils/retry";

/**
 * The WebhooksService keeps track of webhooks associated with a given case. Details of a case
 * (e.g. keyed by caseId) are kept track of within an instance of the service. Webhook state is
 * kept for the service through a store of @type {Record<CaseId, WebhookInfoForCase>}.
 *
 * When cases are added to the service, we make an API request to Moria to determine which
 * webhooks will fire given the labels that have been set for the case. As labels are added and
 * removed, the webhook info displayed will update as well.
 *
 * On the override workflow, the user will have the ability to manually override tags associated
 * with each webhook (and even have the option to disable a webhook altogether). After a user
 * confirms webhook info for the case, we will send a @type {UpdateCaseStatusRequest} to Moria
 * to override the automations that would have fired with the supplied ones instead.
 */
export class WebhooksService {
  private authService: AuthService;
  private eventService: EventGrpcServiceClient;
  private webhooksStore: WebhooksStore = {};

  private constructor(authService: AuthService, eventService: EventGrpcServiceClient) {
    this.authService = authService;
    this.eventService = eventService;
  }

  public static create(authService: AuthService): WebhooksService {
    const { $eventService } = getServices();
    return new WebhooksService(authService, $eventService);
  }

  public addCasesToStore(caseIds: CaseId[], source: FlagSourceEnum): TE.TaskEither<Error, number> {
    const storeAdditionsTE = createStoreFromCases(caseIds, source, this.eventService);
    return pipe(
      storeAdditionsTE,
      TE.map((storeAdditions) => {
        this.webhooksStore = pipe(
          this.webhooksStore,
          R.union(webhookInfoForCaseMagma)(storeAdditions)
        );
        return caseIds.length;
      })
    );
  }

  private setClient<A>(f: { setClient: (value: string) => A }) {
    const company = this.authService.companyOrLogout();
    return f.setClient(company.clientid);
  }

  /**
   * Method that leverages the webhook store to return which webhooks will fire for the case as it
   * currently stands.
   *
   * @returns an array of @type {WebhookInfo[]} active for this case
   */
  public getSelectedWebhooksForCase(caseId: CaseId): WebhookInfo[] {
    return pipe(
      this.getAllWebhooksForCase(caseId),
      A.filter(({ selected }) => selected)
    );
  }

  /**
   * Method that leverages the webhook store to return all webhooks associated with the case.
   *
   * @returns an array of @type {WebhookInfo[]}
   */
  public getAllWebhooksForCase(caseId: CaseId): WebhookInfo[] {
    return pipe(
      this.webhooksStore,
      R.lookup(caseId),
      O.fold(
        () => [],
        (w) => w.webhookInfos
      )
    );
  }

  /**
   * Method that leverages the webhook store to return override notes associated with the case.
   *
   * @returns a @type {string}
   */
  public getNotesForCase(caseId: CaseId): string {
    return pipe(
      this.webhooksStore,
      R.lookup(caseId),
      O.fold(
        () => "",
        (w) => w.overrideNotes || ""
      )
    );
  }

  /**
   * Updates the webhooks in the store based on changes made by the user to labels.
   *
   * @param adjustmentsInput -- input containing added and/or removed labels
   */
  public refreshWebhooksForCase(
    adjustmentsInput: GetAutomationsInput
  ): TE.TaskEither<Error, number> {
    const updatedStoreTE = getAutomationsForCase(this.eventService, adjustmentsInput);
    const existingNotes = pipe(
      this.webhooksStore,
      R.lookup(adjustmentsInput.caseId),
      O.fold(
        () => "",
        (w) => w.overrideNotes
      )
    );

    log.info("Updating webhooks store with these adjustments", adjustmentsInput);
    return pipe(
      updatedStoreTE,
      TE.map(webhookInfoForCaseFromOutput),
      TE.map((updatedWebhookInfos) => {
        this.webhooksStore = pipe(
          this.webhooksStore,
          R.upsertAt(adjustmentsInput.caseId, {
            webhookInfos: updatedWebhookInfos,
            overrideNotes: existingNotes
          } as WebhookInfoForCase)
        );
        return 1;
      })
    );
  }

  /**
   * Updates webhook info in the store to add a set of tags for the corresponding webhook.
   *
   * @param caseId         -- case id to look up webhook info in store
   * @param subscriptionId -- subscription id to look up webhook info
   * @param addedTags      -- tags to add to webhook info
   */
  public addTagsForWebhook(
    caseId: CaseId,
    subscriptionId: SubscriptionId,
    addedTags: Set<TagName>
  ): WebhooksStoreUpdate {
    const updateTagFn = (w: WebhookInfo) => {
      const additionalTags = pipe(w.additionalTags, S.union(Str.Eq)(addedTags));
      const activeTags = pipe(w.activeTags, S.union(Str.Eq)(addedTags));
      const disabledTags = pipe(w.disabledTags, S.difference(Str.Eq)(addedTags));
      return { ...w, activeTags, disabledTags, additionalTags } as WebhookInfo;
    };
    return pipe(
      this.updateWebhookForCase(caseId, subscriptionId, updateTagFn),
      E.map((updatedStore) => {
        this.webhooksStore = updatedStore;
        return 1;
      })
    );
  }

  /**
   * Updates webhook info in the store to remove all added tags for the corresponding webhook.
   *
   * @param caseId         -- case id to look up webhook info in store
   * @param subscriptionId -- subscription id to look up webhook info
   */
  public removeAddedTagsForWebhook(
    caseId: CaseId,
    subscriptionId: SubscriptionId
  ): WebhooksStoreUpdate {
    const updateTagFn = (w: WebhookInfo) => {
      const activeTags = pipe(w.activeTags, S.difference(Str.Eq)(w.additionalTags));
      const disabledTags = pipe(w.disabledTags, S.difference(Str.Eq)(w.additionalTags));
      const additionalTags = new Set<TagName>();
      return { ...w, activeTags, disabledTags, additionalTags } as WebhookInfo;
    };
    return pipe(
      this.updateWebhookForCase(caseId, subscriptionId, updateTagFn),
      E.map((updatedStore) => {
        this.webhooksStore = updatedStore;
        return 1;
      })
    );
  }

  /**
   * Updates webhook info in the store to toggle a tag for the corresponding webhook. In this
   * context, toggling means either making an active tag disabled or making a disabled tag active.
   *
   * @param caseId         -- case id to look up webhook info in store
   * @param subscriptionId -- subscription id to look up webhook info
   * @param tag            -- name of tag to toggle
   */
  public toggleTagForWebhook(
    caseId: CaseId,
    subscriptionId: SubscriptionId,
    tag: TagName
  ): WebhooksStoreUpdate {
    const updateTagFn = (w: WebhookInfo) => {
      const activeTags = pipe(w.activeTags, S.toggle(Str.Eq)(tag));
      const disabledTags = pipe(w.disabledTags, S.toggle(Str.Eq)(tag));
      return { ...w, activeTags, disabledTags } as WebhookInfo;
    };
    return pipe(
      this.updateWebhookForCase(caseId, subscriptionId, updateTagFn),
      E.map((updatedStore) => {
        this.webhooksStore = updatedStore;
        return 1;
      })
    );
  }

  /**
   * Updates webhook info in the store to toggle whether or not a webhook is enabled.
   *
   * @param caseId         -- case id to look up webhook info in store
   * @param subscriptionId -- subscription id of webhook to toggle
   */
  public toggleWebhook(caseId: CaseId, subscriptionId: SubscriptionId): WebhooksStoreUpdate {
    const selectFn = (w: WebhookInfo) => ({ ...w, selected: !w.selected } as WebhookInfo);
    return pipe(
      this.updateWebhookForCase(caseId, subscriptionId, selectFn),
      E.map((updatedStore) => {
        this.webhooksStore = updatedStore;
        return 1;
      })
    );
  }

  /**
   * Update the webhooks store with override notes for a particular case.
   *
   * @param caseId     -- case id to update override notes for
   * @param notesToAdd -- content of note as a @type {string}
   */
  public addNotesForOverride(caseId: CaseId, notesToAdd: string): WebhooksStoreUpdate {
    return pipe(
      this.webhooksStore,
      R.lookup(caseId),
      E.fromOption(() => new WebhookNotFoundForCaseError(caseId)),
      E.map(({ webhookInfos }) => {
        this.webhooksStore = pipe(
          this.webhooksStore,
          R.upsertAt(caseId, {
            webhookInfos,
            overrideNotes: notesToAdd
          } as WebhookInfoForCase)
        );
        return 1;
      })
    );
  }

  /**
   * Within the context of a client, this method returns all possible tags associated with that
   * client. These tags can then be used for augmenting enabled webhooks for overrides.
   *
   * @returns a @type {TE.TaskEither<Error, Set<TagName>>} containing either an error or the set of tag names
   */
  public getAllWebhookTagsForClient(): TE.TaskEither<Error, Set<TagName>> {
    const requestWithoutClient = new ClientMessage();
    const request = this.setClient(requestWithoutClient);
    return pipe(
      retryAPI(() => this.eventService.getTags(request, null)),
      TE.map((response) => S.fromArray(Str.Eq)(response.getValuesList()))
    );
  }

  /**
   * Looks up all webhook info for a case and then converts that info to an array of
   * @type {AutomationOverrideMessage}. If there is an issue creating the override messages,
   * then an error message is logged and an empty array is returned.
   *
   * @param caseId -- case id to look up webhook info in store
   */
  public getAutomationOverridesForCase(caseId: CaseId): AutomationOverrideMessage[] {
    return pipe(
      this.createAutomationOverridesForCase(caseId),
      E.match((err) => {
        log.error(err, `Could not create automation overrides for [case: ${caseId}]`);
        return [] as AutomationOverrideMessage[];
      }, identity)
    );
  }

  /**
   * Given case ids, sends a batch update request of webhook overrides (@type {AutomationOverrideMessage})
   * associated with each case.
   *
   * @param caseIdsToSend -- cases for which we want to override existing webhooks
   * @param source        -- source to attach to update message
   */
  public sendAutomationOverrides(
    caseIdsToSend: CaseId[],
    source: FlagSourceEnum
  ): TE.TaskEither<Error, MultiUserContentMessage> {
    const batchUpdateForCases = pipe(
      caseIdsToSend,
      A.map((caseId) => {
        return pipe(
          this.createAutomationOverridesForCase(caseId),
          E.map((overrideMessages) =>
            new UpdateCaseStatusRequest()
              .setCaseid(caseId)
              .setAutomationoverridesList(overrideMessages)
              .setSource(source)
          )
        );
      }),
      errorTraverse<UpdateCaseStatusRequest, BatchCaseUpdateRequest>((updateRequests) =>
        new BatchCaseUpdateRequest().setUpdatesList(updateRequests)
      )
    );

    log.info("Sending automation overrides for cases", caseIdsToSend);
    return pipe(
      TE.fromEither(batchUpdateForCases),
      TE.chain(
        TE.tryCatchK(
          (request) => this.eventService.batchCaseUpdate(request, null),
          (error) => rpcErrToError(error as RpcError)
        )
      )
    );
  }

  public getWebhooksToBulkInfo(selectedCaseInfos: CaseInfo[]): WebhooksToBulkInfo {
    const webhooksAndSelectedCaseIds = pipe(
      this.webhooksStore,
      R.toArray,
      A.filter(([caseId, _]) =>
        pipe(
          selectedCaseInfos,
          A.some((c) => c.caseId === caseId)
        )
      ),
      A.chain(([caseId, webhookInfoForCase]) =>
        pipe(
          webhookInfoForCase.webhookInfos,
          A.map((w) => {
            const matchingCaseInfo = pipe(
              selectedCaseInfos,
              A.findFirst((c) => c.caseId === caseId)
            );
            const caseInfos = pipe(matchingCaseInfo, A.fromOption);
            return [w.subscriptionId, { webhookInfo: w, caseInfos }] as WebhooksAndInfo;
          })
        )
      )
    );

    const webhooksToCases = R.fromFoldableMap(webhookAndCaseInfoSemigroup, A.Foldable)(
      webhooksAndSelectedCaseIds,
      identity
    );

    return pipe(
      webhooksToCases,
      R.map(({ webhookInfo, caseInfos }) => {
        const selectedCase = pipe(
          caseInfos,
          A.head,
          O.map((c) => c.caseId)
        );
        return {
          ...webhookInfo,
          caseInfos,
          selectedCase,
          overrideNotes: ""
        } as BulkWebhookInfo;
      })
    );
  }

  /**
   * Sends a batch update request given a list of update requests corresponding to cases to be
   * bulk overriden.
   *
   * @param updateRequests -- array of update requests that to be aggregated in the batch request
   * @returns
   */
  public bulkOverrideWithUpdateRequests(
    updateRequests: UpdateCaseStatusRequest[]
  ): TE.TaskEither<Error, MultiUserContentMessage> {
    updateRequests.forEach((updateRequest) =>
      log.info(`request`, JSON.stringify(updateRequest.toObject()))
    );
    const batchUpdateForCases = new BatchCaseUpdateRequest().setUpdatesList(updateRequests);
    return pipe(
      E.right(batchUpdateForCases),
      TE.fromEither,
      TE.chain(
        TE.tryCatchK(
          (request) => this.eventService.batchCaseUpdate(request, null),
          (error) => rpcErrToError(error as RpcError)
        )
      )
    );
  }

  // PRIVATE METHODS //

  private createAutomationOverridesForCase(
    caseId: CaseId
  ): E.Either<Error, AutomationOverrideMessage[]> {
    return pipe(
      this.webhooksStore,
      R.lookup(caseId),
      O.map((webhookInfoForCase) => automationOverridesFromWebhookInfoForCase(webhookInfoForCase)),
      E.fromOption(() => new WebhookNotFoundForCaseError(caseId))
    );
  }

  private updateWebhookForCase(
    caseId: CaseId,
    subscriptionId: SubscriptionId,
    updateFn: (w: WebhookInfo) => WebhookInfo
  ): E.Either<Error, WebhooksStore> {
    const { webhookInfos, overrideNotes } = pipe(
      this.webhooksStore,
      R.lookup(caseId),
      O.fold(() => defaultInfoForCase, identity)
    );

    const updatedWebhookStoreOpt = pipe(
      webhookInfos,
      A.findIndex((w) => w.subscriptionId === subscriptionId),
      O.chain((i) => pipe(webhookInfos, A.modifyAt(i, updateFn))),
      O.map((updatedWebhookInfos) => {
        const update = {
          webhookInfos: updatedWebhookInfos,
          overrideNotes
        } as WebhookInfoForCase;
        return pipe(this.webhooksStore, R.upsertAt(caseId, update));
      })
    );

    return pipe(
      updatedWebhookStoreOpt,
      E.fromOption(() => new WebhookNotFoundForCaseError(caseId))
    );
  }
}


import "reflect-metadata";
import Vue, { PropType } from "vue";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { GridOptions, RowNode } from "ag-grid-community";
import GrdGrid from "~/components/grid.vue";
import GrdGridHeader from "~/components/gridHeader.vue";
import { CaseRowForWebhook } from "~/services/webhooks/models/WebhookModels";
import BulkCaseTableRow from "~/components/override-actions/bulk-level/BulkCaseTableRow.vue";
import { GridOptionsService } from "~/services/ClientDataGridOptionsService";

export default Vue.extend({
  name: "BulkCaseTable",
  components: { GrdGrid, GrdGridHeader },

  props: {
    subscriptionId: {
      required: true,
      type: String
    },
    subscriptionDisabled: {
      default: false,
      type: Boolean
    },
    caseTable: {
      default: (): CaseRowForWebhook[] => [] as CaseRowForWebhook[],
      type: Array as PropType<CaseRowForWebhook[]>
    }
  },
  data() {
    return {
      caseTableErrorMessage: "Cannot display cases for bulk overrides, please try again later!",
      displayCaseTableError: false,
      gridHeaders: ["Select Case", "Case Labels", "Case Text"],
      columnTemplate: "1fr 1fr 50%"
    };
  },
  computed: {
    gridOptions(): GridOptions {
      return new GridOptionsService<CaseRowForWebhook>([], this.getRowRenderer()).getGridOptions();
    }
  },
  watch: {
    caseTable() {
      this.gridOptions.api?.setRowData(this.caseTable);
    }
  },
  mounted() {
    this.gridOptions.api?.setRowData(this.caseTable);
  },
  methods: {
    getRowRenderer(): ({ node }: { node: RowNode }) => Element {
      return ({ node }: { node: RowNode }) => {
        const { data } = node;
        // mount the component and return it's element if data is not null. Otherwise return an empty div
        return pipe(
          O.fromNullable(data),
          O.fold(
            () => document.createElement("div"),
            () => {
              const instance = new BulkCaseTableRow({
                propsData: {
                  subscriptionId: this.subscriptionId,
                  subscriptionDisabled: this.subscriptionDisabled,
                  caseRowForWebhook: data as CaseRowForWebhook,
                  columnTemplate: this.columnTemplate
                },
                parent: this
              }).$mount();

              return instance.$el;
            }
          )
        );
      };
    }
  }
});

import { Plugin } from "@nuxt/types";
// eslint-disable-next-line
import { ClientServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/clients/ClientsServiceClientPb";
import { RuleServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/config/rules/ServiceServiceClientPb";
// eslint-disable-next-line
import { NavigationServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/navigation/NavigationServiceClientPb";
import { LookerServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/looker/LookerServiceClientPb";
import { UserServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/users/UsersServiceClientPb";
// eslint-disable-next-line
import { EventGrpcServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/Event-serviceServiceClientPb";
import { LoginServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/login/LoginServiceClientPb";
import { LogoutServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/logout/LogoutServiceClientPb";
import { pipe } from "fp-ts/lib/function";
import * as FpBoolean from "fp-ts/boolean";
import * as grpc from "grpc-web";

let $clientService: ClientServiceClient;
let $navigationService: NavigationServiceClient;
let $lookerService: LookerServiceClient;
let $userService: UserServiceClient;
let $eventService: EventGrpcServiceClient;
let $ruleService: RuleServiceClient;
let $loginService: LoginServiceClient;
let $logoutService: LogoutServiceClient;

class UnaryInterceptor implements grpc.UnaryInterceptor<any, any> {
  intercept(
    request: grpc.Request<any, any>,
    invoker: (rq: grpc.Request<any, any>) => Promise<grpc.UnaryResponse<any, any>>
  ) {
    const { checksumai } = window;
    return invoker(request).then((response) => {
      const networkEvent = {
        url: request.getMethodDescriptor().getName(),
        request_method: request.getMethodDescriptor().getName(),
        request_payload: request.getRequestMessage(),
        response_status: response.getStatus().code,
        response: response.getResponseMessage()
      };
      checksumai.capture("network_request", networkEvent);
      return response;
    });
  }
}

const servicesPlugin: Plugin = (context) => {
  const interceptor = new UnaryInterceptor();
  const serviceOptions = pipe(
    context.env.spectrumEnvironment === "prod",
    FpBoolean.match(
      () => {
        return {
          withCredentials: true,
          unaryInterceptors: [interceptor]
        };
      },
      () => {
        return {
          withCredentials: true
        };
      }
    )
  );

  $clientService = new ClientServiceClient(context.env.baseUrl, null, serviceOptions);

  $navigationService = new NavigationServiceClient(context.env.baseUrl, null, serviceOptions);

  $lookerService = new LookerServiceClient(context.env.baseUrl, null, serviceOptions);

  $userService = new UserServiceClient(context.env.baseUrl, null, serviceOptions);

  $eventService = new EventGrpcServiceClient(context.env.baseUrl, null, serviceOptions);

  $ruleService = new RuleServiceClient(context.env.baseUrl, null, serviceOptions);

  $loginService = new LoginServiceClient(context.env.baseUrl, null, serviceOptions);

  $logoutService = new LogoutServiceClient(context.env.baseUrl, null, serviceOptions);
};

export default servicesPlugin;

// Hack because inject does not work with vuex module decorators. So we just import these for use in store.
export function getServices() {
  return {
    $clientService,
    $navigationService,
    $lookerService,
    $userService,
    $eventService,
    $ruleService,
    $loginService,
    $logoutService
  };
}

import { Plugin } from "@nuxt/types";
import log from "loglevel";
import { getAuthStore } from "~/store";
import { AuthService, AuthServiceImpl } from "~/services/authService";
import { UserModerationDataService } from "~/services/user-moderation/UserModerationDataService";
import { PageTitleService } from "~/services/PageTitleService";
import { CaseModerationDataService } from "~/services/moderation/CaseModerationDataService";
import { WebhooksService } from "~/services/webhooks/WebhooksService";

let $authService: AuthService;

declare global {
  interface Window {
    spcLogger: log.RootLogger;
  }
}

const appServicePlugin: Plugin = async (_, inject) => {
  log.setDefaultLevel(log.levels.INFO);
  log.trace("starting services plugin");
  window.spcLogger = log;
  $authService = new AuthServiceImpl(getAuthStore());
  const userModerationService = UserModerationDataService.createFromDefaults($authService);
  const caseModerationService = CaseModerationDataService.createFromDefaults($authService);
  const webhooksService = WebhooksService.create($authService);
  const titleService = new PageTitleService();

  // log the user in
  await $authService.loginAndSaveClient();

  // inject into Vue and nuxt context
  inject("authService", $authService);
  inject("userModerationService", userModerationService);
  inject("caseModerationService", caseModerationService);
  inject("webhooksService", webhooksService);
  inject("titleService", titleService);
};

export default appServicePlugin;

// Hack because inject does not work with vuex module decorators or our own services which are not aware of Vue or nuxt instances. So we just import the function for use in those scenarios.
export function getAuthService() {
  return {
    $authService
  };
}

import { CaseStatusEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";

export enum ModalType {
  CLEAR = "clear",
  CONFIRM = "confirm",
  CONFIRM_AND_SKIP_ACTION = "confirm and skip action",
  DEFAULT = "default",
  OVERRIDE = "override",
  BULK_OVERRIDE = "bulk override"
}

export enum ModalEventType {
  CLOSE = "close",
  OPEN = "open",
  UPDATE = "update"
}

export interface ModalDataType {}

export interface ConfirmModalData extends ModalDataType {
  cases: any[];
  caseStatus: CaseStatusEnum;
  modalType: ModalType;
}

export interface ConfirmResponse {
  confirmed: boolean;
  modalType: ModalType;
  cases: any[];
  originalStatus: CaseStatusEnum;
}

export interface ModalClosedResponse {
  isOpen: boolean;
}

export function defaultForModalType<A extends ModalDataType>(): A {
  return {} as A;
}

// export type ModalDataType = ConfirmModalData | ModerationDataRow;

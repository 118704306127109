import { GridOptions, RowNode } from "ag-grid-community";
import { pipe } from "fp-ts/lib/function";
import * as Option from "fp-ts/lib/Option";
import { BaseGridOptionsService } from "~/services/BaseGridOptionsService";

// The members of this class come together here to form the final options for moderation grid
export class GridOptionsService<T> extends BaseGridOptionsService {
  constructor(rowData: T[], rowRenderer: ({ node }: { node: RowNode }) => Element) {
    super();
    this.rowRenderer = rowRenderer;
    this.rowData = rowData;
  }

  // the only public method exposed by this class
  getGridOptions(): GridOptions {
    return pipe(
      this.gridOptions,
      Option.getOrElse(() => {
        const options: GridOptions = { ...this.basicGridOptions(), ...this.clientDataOptions };
        this.gridOptions = Option.of(options);
        return options;
      })
    );
  }

  private rowData: any[] = [];
  private gridOptions: Option.Option<GridOptions> = Option.none;

  rowRenderer: ({ node }: { node: RowNode }) => Element;

  // these options are specific to infinite row model of our grid library
  private clientDataOptions: GridOptions = {
    rowData: this.rowData
  };
}

// libraries we use
import { RpcError } from "grpc-web";
import { contramap } from "fp-ts/lib/Ord";
import { pipe } from "fp-ts/lib/function";
import * as FpBoolean from "fp-ts/lib/boolean";
import * as TaskEither from "fp-ts/lib/TaskEither";
import * as FpMap from "fp-ts/lib/Map";
import * as FpNumber from "fp-ts/number";
import * as FpString from "fp-ts/lib/string";
import * as FpArray from "fp-ts/lib/Array";
import * as Option from "fp-ts/lib/Option";

import log from "loglevel";

// spectrum grpc libs
// eslint-disable-next-line max-len
import { EventGrpcServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/Event-serviceServiceClientPb";
import * as EventsPb from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import {
  CaseStatusEnum,
  ClientMessage,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import {
  GetFlaggedAspectsRequest,
  GetFlaggedAspectsResponse,
  GetNextFlaggedAspectsRequest
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";

// other modules in Durin
import { AuthService } from "../authService";
import { UserModerationRow } from "./models/UserModerationModels";
import UserQueueStore from "~/store/userQueue";
import { msgToQueueData } from "~/services/moderation/CaseModerationDataService";
import { ModerationDataRow } from "~/services/moderation/models/CaseModerationModels";
import { getUserQueueStore } from "~/store";
import { getServices } from "~/plugins/grpcServices";
import { rpcErrToError } from "~/utils/converters";
import { retryAPI } from "~/utils/retry";

export class UserModerationDataService {
  constructor(
    eventService: EventGrpcServiceClient,
    store: UserQueueStore,
    authService: AuthService
  ) {
    this.eventService = eventService;
    this.store = store;
    this.authService = authService;
  }

  public static createFromDefaults(authService: AuthService) {
    const userQueueStore = getUserQueueStore();
    const { $eventService } = getServices();

    return new UserModerationDataService($eventService, userQueueStore, authService);
  }

  private eventService: EventGrpcServiceClient;
  private store: UserQueueStore;
  private authService: AuthService;
  private userIdAttribute = "user-id";
  public productAttribute = "product";

  /*
   * Function that converts a FlaggedAspectCollectionMessage to UserModerationRow
   */
  private aspectToUserRow(aspectMsg: EventsPb.FlaggedAspectCollectionMessage): UserModerationRow {
    const msgObj = aspectMsg.toObject();
    // TODO: translate from rank to severity, figure out what goes in metadata
    return {
      user: msgObj.aspect ? msgObj.aspect.collectionid : "",
      severity: msgObj.rank,
      behaviors: msgObj.behaviorcountsMap,
      messageCount: msgObj.flaggedmessagecount,
      languages: msgObj.languagesList
    };
  }

  private getFilterPredicate() {
    return pipe(
      new EventsPb.PredicateMessage(),
      this.setBehaviorPredicate,
      this.setLanguagePredicate,
      this.setProductPredicate
    );
  }

  private setBehaviorPredicate = (predicate: EventsPb.PredicateMessage) => {
    const behaviors = this.store.behaviors;

    const behaviorPredicate = new EventsPb.BehaviorPredicateMessage().setIncludedbehaviorsList(
      behaviors
    );

    return predicate.setBehaviorpredicate(behaviorPredicate);
  };

  private setLanguagePredicate = (predicate: EventsPb.PredicateMessage) => {
    const languages = this.store.languages;
    const includeUnknown = this.store.filterValues.includeUnknownLanguages;

    const languagePredicate = new EventsPb.LanguagePredicateMessage()
      .setIncludedlanguagesList(languages)
      .setIncludeunknown(includeUnknown);

    return predicate.setLanguagepredicate(languagePredicate);
  };

  private setProductPredicate = (predicate: EventsPb.PredicateMessage) => {
    const products = this.store.products;

    const attributePredicate = new EventsPb.AttributePredicateMessage()
      .setAttributename(this.productAttribute)
      .setAcceptedvaluesList(products);

    return predicate.setAttributepredicate(attributePredicate);
  };

  /*
   * A function that invokes the InitialFlaggedAspects request
   */
  private getInitialFlaggedAspects(limit: number) {
    const requestWithoutClient = new GetFlaggedAspectsRequest()
      .setCollection(EventsPb.AspectCollectionEnum.USERS)
      .setPredicate(this.getFilterPredicate())
      .setLimit(limit);

    const request = this.setClient(requestWithoutClient);
    log.info("Getting initial set of flagged aspects", request.toObject());
    return retryAPI(() => this.eventService.getFlaggedAspects(request, null));
  }

  /*
   * Function that invokes the NextFlaggedAspects call given a cursor
   */
  private getNextFlaggedAspects(cursor: string, limit: number) {
    const request = new GetNextFlaggedAspectsRequest().setCursor(cursor).setLimit(limit);
    log.info("Getting next set of flagged aspects", request.toObject());
    return retryAPI(() => this.eventService.getNextFlaggedAspects(request, null));
  }

  /*
   * The public api function exposed by this class which does the job of invoking the right method : getInitialAspects or getNextAspects
   * depending on the passed in startRow and endRow. Also manages the storage of appropriate cursors in the state so that they can be used to fetch
   * subsequent or previous pages.
   */
  public getFlaggedAspects(
    startRow: number,
    endRow: number
  ): TaskEither.TaskEither<Error, UserModerationRow[]> {
    const pageKey = `${endRow}`;
    const cursorLookupKey = `${startRow}`;
    const limit = endRow - startRow;

    const commitCursor = (result: GetFlaggedAspectsResponse) => {
      const cursor = result.getCursor();
      this.store.updateCursor({ pageKey, cursor });
    };

    const getNextPage = (cursors: Map<string, string>, pageKey: string, limit: number) => {
      const error = new Error(`Error fetching next page. key ${pageKey}, cursors: ${cursors}`);
      return pipe(
        FpMap.lookup(FpString.Eq)(pageKey, cursors),
        Option.fold(
          () => TaskEither.left(error),
          (cursor) => this.getNextFlaggedAspects(cursor, limit)
        )
      );
    };

    return pipe(
      startRow === 0,
      FpBoolean.fold(
        () => getNextPage(this.store.userQueueState.cursors, cursorLookupKey, limit),
        () => this.getInitialFlaggedAspects(limit)
      ),
      TaskEither.chainFirst((result) => TaskEither.of(commitCursor(result))),
      TaskEither.map((result) => result.getAspectsList()),
      TaskEither.map(FpArray.map(this.aspectToUserRow))
    );
  }

  private getUserIdAttributePredicate(userId: string) {
    const attributePredicateMessage = new EventsPb.AttributePredicateMessage()
      .setAttributename(this.userIdAttribute)
      .setAcceptedvaluesList([userId]);

    return attributePredicateMessage;
  }

  public getFlaggedAspectForUser(
    userId: string
  ): TaskEither.TaskEither<Error, EventsPb.FlaggedAspectCollectionMessage> {
    const collectionIdMsg = new EventsPb.CollectionIdMessage()
      .setCollection(EventsPb.AspectCollectionEnum.USERS)
      .setCollectionid(userId);

    const request = new EventsPb.GetFlaggedAspectRequest().setAspect(
      this.setClient(collectionIdMsg)
    );

    log.info("Getting flagged aspect for user", request.toObject());
    return pipe(this.setClient(request), (r) =>
      retryAPI(() => this.eventService.getFlaggedAspect(r, null))
    );
  }

  public batchCaseUpdate(cases: ModerationDataRow[], status: CaseStatusEnum) {
    const toUpdateCaseStatusRequest = (flaggedCase: ModerationDataRow) => {
      const getAdjustmentList = () => {
        switch (status) {
          case CaseStatusEnum.CLEAREDBYMODERATOR:
            return pipe(
              flaggedCase.labels,
              FpArray.map((l) => {
                const msg = new EventsPb.BehaviorAdjustmentMessage()
                  .setBehavior(l.behavior)
                  .setAdjustmenttype(EventsPb.AdjustmentTypeEnum.CLEARED);
                return msg;
              })
            );
          default:
            return [];
        }
      };

      return new EventsPb.UpdateCaseStatusRequest()
        .setCaseid(flaggedCase.caseId)
        .setAdjustmentsList(getAdjustmentList())
        .setSource(FlagSourceEnum.MODERATOR)
        .setNewstatus(status)
        .setCreatedby(this.authService.userOrLogout().email);
    };

    // list of case statuses which are valid candidates for batch operation
    const unmoderatedCaseStatuses = new Set([CaseStatusEnum.PENDINGREVIEW, CaseStatusEnum.MUTED]);

    const hasValidStatus = (c: ModerationDataRow) => {
      return pipe(
        c.status,
        Option.fromNullable,
        Option.match(
          () => false,
          (status) => unmoderatedCaseStatuses.has(status)
        )
      );
    };

    const request = pipe(
      cases,
      FpArray.filter(hasValidStatus),
      FpArray.map(toUpdateCaseStatusRequest),
      (updateRequests) => {
        log.info(`updating ${updateRequests.length} cases`);
        return new EventsPb.BatchCaseUpdateRequest().setUpdatesList(updateRequests);
      }
    );

    const responseToModerationRow = msgToQueueData(this.authService.companyOrLogout());

    log.info("Batch case update for request", request.toObject());
    return pipe(
      request,
      TaskEither.tryCatchK(
        (request) => this.eventService.batchCaseUpdate(request, null),
        (err) => rpcErrToError(err as RpcError)
      ),
      TaskEither.map((result) => result.getMessagesList()),
      TaskEither.map((msgList) => msgList.map((m) => m.toObject())),
      TaskEither.map((msgList) => msgList.map(responseToModerationRow))
    );
  }

  private setClient<A>(f: { setClient: (value: string) => A }) {
    const company = this.authService.companyOrLogout();
    return f.setClient(company.clientid);
  }

  public getPendingCasesForUser(userId: string, caseStatus: CaseStatusEnum, requestLimit: number) {
    const attributePredicate = this.getUserIdAttributePredicate(userId);
    const caseStatusPredicate = new EventsPb.CaseStatusPredicateMessage().setIncludedstatusesList([
      caseStatus
    ]);

    const predicate = new EventsPb.PredicateMessage()
      .setAttributepredicate(attributePredicate)
      .setCasestatuspredicate(caseStatusPredicate);

    // server side paginating on this incurs additional housekeeping of cursors we will do it if needed
    const requestWithoutClient = new EventsPb.GetFlaggedEventsRequest()
      .setPredicate(predicate)
      .setLimit(requestLimit);

    const responseToModerationRow = msgToQueueData(this.authService.companyOrLogout());

    const byWeight = pipe(
      FpNumber.Ord,
      contramap((row: ModerationDataRow) => row.weight * -1)
    );
    const sortByWeight = FpArray.sortBy([byWeight]);

    log.info("Getting pending cases for user", requestWithoutClient.toObject());
    return pipe(
      this.setClient(requestWithoutClient),
      (r) => retryAPI(() => this.eventService.getFlaggedEvents(r, null)),
      TaskEither.map((response) => response.getMessagesList()),
      TaskEither.map((msgList) => msgList.map((m) => responseToModerationRow(m.toObject()))),
      TaskEither.map((moderationRows) => sortByWeight(moderationRows))
    );
  }

  public getPendingCaseCountForUser(userId: string, caseStatus: CaseStatusEnum) {
    const attributePredicate = this.getUserIdAttributePredicate(userId);
    const caseStatusPredicate = new EventsPb.CaseStatusPredicateMessage().setIncludedstatusesList([
      caseStatus
    ]);
    const predicate = new EventsPb.PredicateMessage()
      .setAttributepredicate(attributePredicate)
      .setCasestatuspredicate(caseStatusPredicate);

    const requestWithoutClient = new EventsPb.GetFlaggedEventsRequest().setPredicate(predicate);
    log.info("Getting pending case count for user", requestWithoutClient.toObject());
    return pipe(
      this.setClient(requestWithoutClient),
      (r) => retryAPI(() => this.eventService.countFlaggedEvents(r, null)),
      TaskEither.map((resp) => resp.getCount())
    );
  }

  public countFlaggedEventsForUser(userId: string, caseStatuses: CaseStatusEnum[]) {
    const attributePredicate = this.getUserIdAttributePredicate(userId);
    const caseStatusPredicate = new EventsPb.CaseStatusPredicateMessage().setIncludedstatusesList(
      caseStatuses
    );
    const predicate = new EventsPb.PredicateMessage()
      .setAttributepredicate(attributePredicate)
      .setCasestatuspredicate(caseStatusPredicate);
    const requestWithoutClient = new EventsPb.GetFlaggedEventsRequest().setPredicate(predicate);

    log.info("Getting flagged event count for user for request", requestWithoutClient.toObject());
    return pipe(
      this.setClient(requestWithoutClient),
      (r) => retryAPI(() => this.eventService.countFlaggedEvents(r, null)),
      TaskEither.map((resp) => resp.getCount())
    );
  }

  public getFlaggedAspectsCount() {
    const requestWithoutClient = new EventsPb.GetFlaggedAspectsRequest()
      .setCollection(EventsPb.AspectCollectionEnum.USERS)
      .setPredicate(this.getFilterPredicate());

    log.info("Getting flagged aspects count for request", requestWithoutClient.toObject());
    return pipe(
      this.setClient(requestWithoutClient),
      (r) => retryAPI(() => this.eventService.countFlaggedAspects(r, null)),
      TaskEither.map((resp) => resp.getCount())
    );
  }

  public getAspectHistory(userId: string, limit: number) {
    const aspectWithoutClient = new EventsPb.CollectionIdMessage()
      .setCollection(EventsPb.AspectCollectionEnum.USERS)
      .setCollectionid(userId);

    const request = new EventsPb.GetAspectHistoryRequest()
      .setLimit(limit)
      .setAspect(this.setClient(aspectWithoutClient));

    log.info("Getting aspect history for request", request.toObject());
    return retryAPI(() => this.eventService.getAspectHistory(request, null));
  }

  public getAllTags() {
    const requestWithoutClient = new ClientMessage();
    const request = this.setClient(requestWithoutClient);
    log.info("Getting tags for request", request.toObject());
    return retryAPI(() => this.eventService.getTags(request, null));
  }

  public getAttributeValues(attributeName: string) {
    const requestWithoutClient = new EventsPb.GetAttributeValuesRequest().setAttributename(
      attributeName
    );

    log.info(`Getting attribute values for (${attributeName})`);
    const request = this.setClient(requestWithoutClient);
    return retryAPI(() => this.eventService.getAttributeValues(request, null));
  }
}

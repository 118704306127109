import { pipe } from "fp-ts/lib/function";
import * as Str from "fp-ts/lib/string";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as S from "fp-ts/lib/Set";
import {
  WebhookInfo,
  WebhookRow,
  WebhookTag,
  webhookTagEq,
  webhookTagOrd
} from "../models/WebhookModels";

export function webhookInfoToWebhookRow({
  subscriptionName,
  subscriptionId,
  selected,
  activeTags,
  disabledTags,
  additionalTags
}: WebhookInfo): WebhookRow {
  const webhookTags = pipe(
    tagSetToWebhookTagSet(activeTags, true),
    S.union(webhookTagEq)(tagSetToWebhookTagSet(additionalTags, true)),
    S.union(webhookTagEq)(tagSetToWebhookTagSet(disabledTags, false)),
    S.toArray(webhookTagOrd)
  );
  return {
    displayName: getDisplayName(subscriptionName),
    subscriptionId,
    subscriptionName,
    enabled: selected,
    webhookTags
  } as WebhookRow;
}

export function updateTagForWebhookInfo(webhookInfo: WebhookInfo, tagName: string): WebhookInfo {
  const { activeTags, disabledTags } = webhookInfo;
  return {
    ...webhookInfo,
    activeTags: pipe(activeTags, S.toggle(Str.Eq)(tagName)),
    disabledTags: pipe(disabledTags, S.toggle(Str.Eq)(tagName))
  };
}

export function tagSetToWebhookTagSet(tagSet: Set<string>, enabled: Boolean): Set<WebhookTag> {
  return pipe(
    tagSet,
    S.map(webhookTagEq)(
      (tag) => ({ displayName: getDisplayName(tag), tagName: tag, enabled } as WebhookTag)
    )
  );
}

function getDisplayName(tag: string): string {
  const tagElems = tag.split(":");
  const tagHeader = pipe(
    tagElems,
    A.head,
    O.map(toTitleCase),
    O.getOrElse(() => "")
  );
  const tagDetails = pipe(
    tagElems,
    A.tail,
    O.filter((elems) => elems.length > 0),
    O.map((ds) => ` (${pipe(ds, A.map(toTitleCase)).join(", ")})`),
    O.getOrElse(() => "")
  );

  return tagHeader + tagDetails;
}

export function toTitleCase(s: string): string {
  return s
    .replace(/[_-]+/g, " ")
    .toLowerCase()
    .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase());
}

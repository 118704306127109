import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

import * as Option from "fp-ts/Option";
import * as Either from "fp-ts/Either";
import { pipe } from "fp-ts/function";
import * as TaskEither from "fp-ts/TaskEither";
import * as Task from "fp-ts/Task";
import * as FpBoolean from "fp-ts/boolean";
import * as FpArray from "fp-ts/Array";
import * as FpString from "fp-ts/string";
import * as Set from "fp-ts/Set";
import log from "loglevel";

import * as LogoutPb from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/logout/logout_pb";
import * as MoriaCommonPb from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/common/moria_common_pb";
import { User } from "@auth0/auth0-spa-js";
import { getServices } from "~/plugins/grpcServices";
import { getClient } from "~/plugins/auth0Bootstrap";

declare namespace SpcAuth {
  interface UserAndClient {
    user: Option.Option<User>;
    permissions: Option.Option<MoriaCommonPb.PermissionsEnum[]>;
    clientDetails: Option.Option<MoriaCommonPb.CompanyMessage.AsObject>;
  }
  type AuthState = UserAndClient & { isAuthenticated: boolean };
}

@Module({
  name: "auth",
  stateFactory: true,
  namespaced: true
})
export default class AuthStore extends VuexModule {
  public authState: SpcAuth.AuthState = {
    isAuthenticated: false,
    user: Option.none,
    permissions: Option.none,
    clientDetails: Option.none
  };

  // Extract Set<string> comprising of Categories from LicensedBehaviors and LicensedClassifiers
  get categories() {
    type categorizable =
      | MoriaCommonPb.LicensedBehaviorMessage.AsObject
      | MoriaCommonPb.LicensedClassifierMessage.AsObject;
    // turn a list of behaviors/classifiers to a set of categories from those behaviors and classifiers
    const extractCategories = (categorizables: Array<categorizable>) => {
      return pipe(
        categorizables,
        FpArray.filterMap((categorizable) => Option.fromNullable(categorizable.category)),
        Set.fromArray(FpString.Eq)
      );
    };

    return pipe(
      this.authState.clientDetails,
      Option.map((company) => {
        const categoriesFromBehaviors = extractCategories(company.behaviorsList);
        const categoriesFromClassifiers = extractCategories(company.classifiersList);
        return Set.union(FpString.Eq)(categoriesFromBehaviors, categoriesFromClassifiers);
      }),
      Option.map((categories) => Set.toArray(FpString.Ord)(categories)),
      Option.getOrElse(() => [] as Array<string>)
    );
  }

  @Mutation
  setUserAndClient({ user, permissions, clientDetails }: SpcAuth.UserAndClient) {
    log.debug("setting client and user information", user, permissions, clientDetails);
    this.authState.clientDetails = clientDetails;
    this.authState.permissions = permissions;
    this.authState.user = user;
    this.authState.isAuthenticated = Option.isSome(user) && Option.isSome(clientDetails);
  }

  // commits the client information depending on whether we got an error or not
  @Action({ rawError: true })
  commitDetails(result: Either.Either<Error, MoriaCommonPb.LoginMessage.AsObject>) {
    log.debug("committing details", result);
    const { $spcAuthClient } = getClient();
    const login = Option.toNullable(Option.fromEither(result));
    const permissionsOption = Option.fromNullable(login?.rolepermissionsList);
    const companyInfoOption = Option.fromNullable(login?.company);

    const checksumIdentify = (user: User | undefined) => {
      if (process.env.SPECTRUM_ENVIRONMENT !== "prod") {
        return;
      }
      const { checksumai } = window;
      const clientId = pipe(
        companyInfoOption,
        Option.match(
          () => "",
          (client) => client.clientid
        )
      );
      const email = pipe(
        Option.fromNullable(user),
        Option.match(
          () => "",
          (user) => user.email
        )
      );

      checksumai.identify(email, { clientId });
    };

    const commit = pipe(
      () => $spcAuthClient.getUser(),
      Task.map((user) => {
        const userAndClient: SpcAuth.UserAndClient = {
          user: Option.fromNullable(user),
          permissions: permissionsOption,
          clientDetails: companyInfoOption
        };
        checksumIdentify(user);
        return userAndClient;
      }),
      Task.map((userAndClient) => this.context.commit("setUserAndClient", userAndClient))
    );
    commit();
  }

  @Action({ rawError: true })
  async login() {
    log.debug("fetching user information");
    const { $spcAuthClient } = getClient();
    const { $loginService } = getServices();

    // gets an access token from auth0 and obtain clientDetails from Moria
    // only successful when user is already logged in
    const loginAndGetClient = () =>
      pipe(
        TaskEither.tryCatch(
          () => $spcAuthClient.getAccessToken(),
          (error) => new Error(`failed to get token from auth0 ${error}`)
        ),
        TaskEither.chain((accessToken) =>
          TaskEither.tryCatch(
            () =>
              $loginService.login(new MoriaCommonPb.Empty(), {
                Authorization: `Bearer ${accessToken}`
              }),
            (error) => new Error(`failed to obtain a session ${error}`)
          )
        ),
        TaskEither.map((loginDetail) => loginDetail.toObject())
      )();

    // if the user is authenticated, initiate the login flow with Moria otherwise return Error
    const loginMessage = await pipe(
      () => $spcAuthClient.isAuthenticated(), // directly passing $auth0Client.isAuthenticated does not work
      Task.chain((isAuthenticated) => () => {
        return pipe(
          isAuthenticated,
          FpBoolean.fold(
            () => Promise.resolve(Either.left(new Error("User is not authenticated"))),
            () => loginAndGetClient()
          )
        );
      })
    )();
    this.commitDetails(loginMessage);
    return loginMessage;
  }

  // TODO; get rid of this action and move this business logic to authService itself
  @Action({ rawError: true })
  async logout() {
    const { $spcAuthClient } = getClient();
    const { $logoutService } = getServices();
    this.context.commit("setUserAndClient", { user: Option.none, client: Option.none });
    return await pipe(
      TaskEither.tryCatch(
        () => $logoutService.logout(new LogoutPb.Empty(), null),
        (error) => new Error(`Error logging out ${error}`)
      ),
      TaskEither.map((_) => $spcAuthClient.logout({ returnTo: `${window.location.origin}/logout` }))
    )();
  }
}

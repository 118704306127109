import { Context } from "@nuxt/types/app";
import log from "loglevel";
import middlewareFactory from "./middlewareFactory";

/**
 * Middleware meant for pages which are meant for only authenticated users.
 * If an unauthenticated user tries to access these then he is redirected back to login page.
 */

log.debug("in authenticated middleware");
const check = (context: Context) => context.app.$authService.isAuthenticated;
const actionOnFalse = (context: Context) => () => context.redirect("/logout");
const authenticated = middlewareFactory(check)(actionOnFalse, () => () => {});

export default authenticated;


import { Component, Vue } from "nuxt-property-decorator";
import { PermissionsEnum } from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/common/moria_common_pb";
import { IconType } from "@spectrum/pandora";
import { SidebarToggle } from "@spectrum/pandora/dist/types/types";
import { pipe } from "fp-ts/function";
import * as Option from "fp-ts/lib/Option";
import ModalContainer from "~/components/ModalContainer.vue";
import TopBar from "~/components/topbar.vue";
import AuthStore from "~/store/auth";
import { getAuthStore } from "~/store";
import { ModalType } from "~/constants/modals";

const getSidebarIsClosed = () => {
  return pipe(
    localStorage.getItem("sidebarIsClosed"),
    Option.fromNullable,
    Option.fold(
      () => false,
      (isClosed) => JSON.parse(isClosed)
    )
  );
};

@Component({
  components: { ModalContainer, TopBar }
})
export default class Main extends Vue {
  private authStore: AuthStore = getAuthStore();
  protected PermissionsEnum = PermissionsEnum; // So it can be referenced in the template
  protected checkPermission = (permission: PermissionsEnum) =>
    this.$authService.permissions.has(permission);

  protected iconType = IconType;

  data() {
    return {
      isClosed: false,
      modalType: ModalType
    };
  }

  mounted() {
    this.sidebarIsClosed = getSidebarIsClosed();
  }

  get sidebarIsClosed() {
    return this.$data.isClosed;
  }

  set sidebarIsClosed(isClosed) {
    this.$data.isClosed = isClosed;
  }

  protected async signOut() {
    await this.authStore.logout();
  }

  protected handleSidebarVisibilityChanged({ detail }: { detail: SidebarToggle }) {
    this.sidebarIsClosed = detail.isClosed;
    localStorage.setItem("sidebarIsClosed", JSON.stringify(detail.isClosed));
  }
}

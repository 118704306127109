import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import AuthStore from "~/store/auth";

let authStore: AuthStore;

/**
 * A function that creates the typesafe version of a given store.
 * This function is invoked via a vuex plugin when our store is created in store/index.ts
 */
function initializeAuthStore(store: Store<any>): void {
  authStore = getModule(AuthStore, store);
}

// exporting this function instead of authStore value directly because authStore is a mutable 'let' value
// and such exports are discouraged
function getAuthStore(): AuthStore {
  return authStore;
}

export { initializeAuthStore, getAuthStore };

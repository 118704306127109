import { Plugin } from "@nuxt/types";
import mitt, { Emitter } from "mitt";
import { CaseId, SubscriptionId } from "~/services/webhooks/models/WebhookModels";

interface AttributeClickedEvent {
  attributeKey: string;
  attributeValue: string;
}

export interface ToggleCaseEvent {
  subscriptionId: SubscriptionId;
  caseId: CaseId;
}

export type Events = {
  attributeClicked: AttributeClickedEvent;
  toggleCase: ToggleCaseEvent;
};

declare module "vue/types/vue" {
  interface Vue {
    $eventHub: Emitter<Events>;
  }
}

const plugin: Plugin = (_, inject) => {
  const $eventHub: Emitter<Events> = mitt<Events>();
  inject("eventHub", $eventHub);
};

export default plugin;

import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faAddressCard as freeFasFaAddressCard,
  faBars as freeFasFaBars,
  faChartBar as freeFasFaChartBar,
  faCogs as freeFasFaCogs,
  faExchangeAlt as freeFasFaExchangeAlt,
  faList as freeFasFaList,
  faPortrait as freeFasFaPortrait,
  faSignOutAlt as freeFasFaSignOutAlt,
  faRedo as freeFasFaRedo,
  faCog as freeFasFaCog
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaAddressCard,
  freeFasFaBars,
  freeFasFaChartBar,
  freeFasFaCogs,
  freeFasFaExchangeAlt,
  freeFasFaList,
  freeFasFaPortrait,
  freeFasFaSignOutAlt,
  freeFasFaRedo,
  freeFasFaCog
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)

import * as Option from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { User } from "@auth0/auth0-spa-js";
import { SpcAuthClient } from "~/services/spcAuthClient";

interface AuthAttributes {
  accessToken: string;
  idToken: string;
  expiry: string;
}
/**
 * This is a mock class that is designed to mimick the real behavior of auth0 spa client.
 * This is for exclusive use by the auth0MockPlugin when running end to end tests
 */
export class SpcAuthClientMockImpl implements SpcAuthClient {
  private authAttributes: Option.Option<AuthAttributes>;

  constructor(accessToken: string, idToken: string, expiry: string) {
    this.authAttributes = Option.of({ accessToken, idToken, expiry });
  }

  // TODO: figure out how to put user in here
  getUser() {
    return Promise.resolve({
      name: "Tester User",
      "https://app.getspectrum.io/default_languages": []
    } as User);
  }

  getAccessToken() {
    return Promise.resolve(
      pipe(
        this.authAttributes,
        Option.map((attrb) => attrb.accessToken),
        Option.getOrElse(() => "")
      )
    );
  }

  loginWithRedirect() {
    return Promise.resolve(undefined);
  }

  logout() {
    return undefined;
  }

  isAuthenticated() {
    return Promise.resolve(Option.isSome(this.authAttributes));
  }
}

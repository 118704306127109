
import "reflect-metadata";
import Vue, { PropType } from "vue";
import log from "loglevel";
import { Colors } from "@spectrum/pandora";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import { CaseId, CaseRowForWebhook } from "~/services/webhooks/models/WebhookModels";
import { toTitleCase } from "~/services/webhooks/utils/WebhookModalUtils";

export default Vue.extend({
  name: "BulkCaseTableRow",
  props: {
    subscriptionId: {
      required: true,
      type: String
    },
    subscriptionDisabled: {
      default: false,
      type: Boolean
    },
    caseRowForWebhook: {
      required: true,
      type: Object as PropType<CaseRowForWebhook>
    },
    columnTemplate: {
      default: "1fr 1fr 50%",
      type: String
    }
  },
  data() {
    return {
      activeTagToggleColor: this.subscriptionDisabled ? Colors.greyLight : Colors.green
    };
  },
  computed: {
    caseId(): CaseId {
      return this.caseRowForWebhook.caseId;
    },
    selected(): boolean {
      return this.caseRowForWebhook.selected;
    },
    caseLabels(): string {
      const formattedLabels = pipe(this.caseRowForWebhook.caseLabels, A.map(toTitleCase));
      return formattedLabels.join(", ");
    },
    caseText(): string {
      return this.caseRowForWebhook.caseText;
    }
  },
  methods: {
    handleToggleCase(caseId: CaseId) {
      log.info(`emitting 'toggleCase' for [case: ${caseId}, webhook: ${this.subscriptionId}]`);
      this.$nuxt.$eventHub.emit("toggleCase", { caseId, subscriptionId: this.subscriptionId });
    }
  }
});

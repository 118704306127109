import { datadogRum } from "@datadog/browser-rum";
import log from "loglevel";
import { Plugin } from "@nuxt/types";

const datadogPlugin: Plugin = async (context, _) => {
  datadogRum.init({
    applicationId: context.env.datadogApplicationID,
    clientToken: context.env.datadogClientToken,
    site: "datadoghq.com",
    service: "guardian-ui",
    env: context.env.spectrumEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow"
  });

  const orig = log.error;
  log.error = (error: unknown, ...msg: any[]) => {
    orig.apply(this, msg);
    datadogRum.addError(error, msg);
  };

  await datadogRum.startSessionReplayRecording();

  log.info("started Datadog plugin");
};

export default datadogPlugin;

import { Auth0Client, User } from "@auth0/auth0-spa-js";

export interface SpcAuthClient {
  isAuthenticated(): Promise<boolean>;
  getUser(): Promise<User | undefined>;
  getAccessToken(): Promise<string>;
  // eslint will complain about snake case redirect_uri on next line
  // eslint-disable-next-line
  loginWithRedirect(options: { redirect_uri: string; connection: string }): Promise<void>;
  logout(options: { returnTo: string }): void;
}

/*
 * SpcAuthClient implementation that uses Auth0 under the hood. This is used by the auth0Plugin
 */
export class SpcAuthClientAuth0Impl implements SpcAuthClient {
  private $auth0Client: Auth0Client;

  constructor(auth0Client: Auth0Client) {
    this.$auth0Client = auth0Client;
  }

  isAuthenticated() {
    return this.$auth0Client.isAuthenticated();
  }

  getUser() {
    return this.$auth0Client.getUser();
  }

  getAccessToken() {
    return this.$auth0Client.getTokenSilently();
  }

  // ignore because snake case variable is mandated by auth0Client api.
  // eslint-disable-next-line
  loginWithRedirect(options: { redirect_uri: string; connection: string }) {
    return this.$auth0Client.loginWithRedirect(options);
  }

  logout(options: { returnTo: string }) {
    if (process.env.SPECTRUM_ENVIRONMENT !== "prod") {
      const { checksumai } = window;
      checksumai.reset();
    }

    return this.$auth0Client.logout(options);
  }
}

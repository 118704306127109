import { Middleware } from "@nuxt/types";
import { Context } from "@nuxt/types/app";
import * as FpBoolean from "fp-ts/boolean";
import { Lazy, pipe } from "fp-ts/lib/function";
import log from "loglevel";

type Check = (context: Context) => boolean;
type Action = (context: Context) => Lazy<void>;

type MiddlewareFactory = (
  check: Check
) => (actionOnFalse: Action, actionOnTrue: Action) => Middleware;

const middlewareFactory: MiddlewareFactory =
  (check: Check) => (actionOnFalse: Action, actionOnTrue: Action) => (context) => {
    log.debug("Result of check: ", check(context));
    return pipe(check(context), FpBoolean.fold(actionOnFalse(context), actionOnTrue(context)));
  };

export default middlewareFactory;

import { GridOptions, RowNode } from "ag-grid-community";

export abstract class BaseGridOptionsService {
  abstract getGridOptions(): GridOptions;

  abstract rowRenderer: ({ node }: { node: RowNode }) => Element;

  // options for grid
  basicGridOptions: () => GridOptions = () => {
    return {
      columnDefs: [], // empty coldefs because the client page renders the columns separately
      defaultColDef: {
        filter: false,
        sortable: false
      },
      rowHeight: 100,
      onGridSizeChanged() {
        this!.api!.sizeColumnsToFit();
      },
      fullWidthCellRenderer: this.rowRenderer,
      headerHeight: 0,
      isFullWidthRow: () => true,
      enableCellTextSelection: true
    };
  };
}

// eslint-disable-next-line max-len
import { EventGrpcServiceClient } from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/Event-serviceServiceClientPb";
import * as EventsPb from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import * as CommonPb from "@spectrum/grpc-protobuf-client-js/getspectrum/common/common_pb";
import {
  CompanyMessage,
  LicensedBehaviorMessage
} from "@spectrum/grpc-protobuf-client-js/getspectrum/moria/common/moria_common_pb";
import {
  AdjustmentTypeEnum,
  OrderingEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/events/service/event-service_pb";
import {
  CaseStatusEnum,
  FlagSourceEnum
} from "@spectrum/grpc-protobuf-client-js/getspectrum/config/common/common_pb";
import log from "loglevel";
import { RpcError } from "grpc-web";

import { pipe } from "fp-ts/function";
import * as FpArray from "fp-ts/Array";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import * as FpBoolean from "fp-ts/boolean";
import * as FpString from "fp-ts/string";
import * as Either from "fp-ts/Either";
import * as Option from "fp-ts/Option";
import * as TaskEither from "fp-ts/TaskEither";

import { AuthService } from "../authService";
import {
  labelInformationOrd,
  Priority,
  LabelInformation,
  UpdateCaseStatusArgs,
  ModerationDataRow
} from "./models/CaseModerationModels";
import { getServices } from "~/plugins/grpcServices";
import { QueueFilters } from "~/components/moderation/ModerationFiltersTypes";
import { rpcErrToError } from "~/utils";
import { getAuthService } from "~/plugins/appServices";
import { retryAPI, retryTask } from "~/utils/retry";

/**
 * Creates a case-insensitive regex for multiple behavior strings,
 * with spaces interchangeable with dash and underscore symbols.
 * Example: ["csam grooming", "doxxing"]
 * Result: /(^csam[-_ ]+grooming$)|(^doxxing$)/
 * Matches: "csam-grooming", "csam_grooming", "CSAM Grooming", "doxxing", etc...
 */
function behaviorStringsToRegex(strings: Array<string>): RegExp {
  const patterns = strings.map((s) => "(^" + s.replace(/ /g, "[-_ ]+") + "$)");
  return new RegExp(patterns.join("|"), "i");
}

interface PriorityRegex {
  priority: Priority;
  regex: RegExp;
}

const sortedPriorityRegexes: Array<PriorityRegex> = [
  {
    priority: Priority.SEVERE,
    regex: behaviorStringsToRegex([
      "csam discussion",
      "csam grooming",
      "self harm",
      "severe toxic",
      "threat"
    ])
  },
  {
    priority: Priority.HIGH,
    regex: behaviorStringsToRegex([
      "bullying",
      "doxxing",
      "hate speech",
      "radicalization",
      "solicitation of drugs",
      "solicitation of sex",
      "underage"
    ])
  },
  {
    priority: Priority.MEDIUM,
    regex: behaviorStringsToRegex(["insult", "profanity", "sexual"])
  },
  {
    priority: Priority.LOW,
    regex: behaviorStringsToRegex(["spam", "pii"])
  }
];

const getMsgSource = (metadata: EventsPb.CaseMetadataMessage.AsObject | undefined): string => {
  switch (metadata?.source) {
    case FlagSourceEnum.SPECTRUM:
      return "Spectrum";
    case FlagSourceEnum.MODERATOR:
      return "Moderator";
    case FlagSourceEnum.CUSTOMERSYSTEM:
      return "Customer System";
    case FlagSourceEnum.USER:
      return "User";
    default:
      return "Spectrum";
  }
};

const isUserReport = (metadata: EventsPb.CaseMetadataMessage.AsObject | undefined): boolean =>
  !!metadata && metadata.source === FlagSourceEnum.USER;

const getBehaviorAdjustMents = (behaviors: string[], adjustmentType: AdjustmentTypeEnum) => {
  return pipe(
    behaviors,
    FpArray.map((behavior: string) => {
      return new EventsPb.BehaviorAdjustmentMessage()
        .setBehavior(behavior)
        .setAdjustmenttype(adjustmentType);
    })
  );
};

const getClientDetails = () => {
  const { $authService } = getAuthService();

  return Option.getOrElseW(() => {
    throw new Error("ClientDetails not found.");
  })($authService.company);
};

export const updateCaseStatus = ({
  caseId,
  caseStatus,
  automationOverrides = [],
  added = [],
  removed = [],
  notes
}: UpdateCaseStatusArgs) => {
  const { $eventService } = getServices();
  const { $authService } = getAuthService();

  const addAdjustments = getBehaviorAdjustMents(added, AdjustmentTypeEnum.ADDED);
  const removeAdjustments = getBehaviorAdjustMents(removed, AdjustmentTypeEnum.CLEARED);

  const allAdjustments = addAdjustments.concat(removeAdjustments);

  const eventRequest = new EventsPb.UpdateCaseStatusRequest()
    .setCaseid(caseId)
    .setAutomationoverridesList(automationOverrides)
    .setAdjustmentsList(allAdjustments)
    .setSource(FlagSourceEnum.MODERATOR)
    .setNewstatus(caseStatus)
    .setNotes(notes)
    .setCreatedby($authService.userOrLogout().email);

  log.info("updating case status", eventRequest.toObject());

  return pipe(
    eventRequest,
    TaskEither.tryCatchK(
      (request) => $eventService.updateCaseStatus(request, null),
      (error) => rpcErrToError(error as RpcError)
    ),
    TaskEither.chain((response) =>
      TaskEither.of(msgToQueueData(getClientDetails())(response.toObject()))
    )
  );
};

export const fetchContext = async ({
  caseData,
  collectionId
}: {
  caseData: ModerationDataRow;
  collectionId: EventsPb.CollectionIdMessage.AsObject;
}) => {
  if (caseData.caseId && caseData.category) {
    const response = await getEvents(caseData.caseId, caseData.category, collectionId)();
    log.info("got response for fetchContext call", response);
    return response;
  } else {
    return Promise.resolve(
      Either.left(new Error(`Case does not have valid caseId and category. ${caseData}`))
    );
  }
};

export const getFilteredBehaviorsList = (behaviors: Array<[string, number]>) => {
  const { $authService } = getAuthService();
  const allBehaviors = $authService.allBehaviors;

  return pipe(
    behaviors,
    FpArray.flatten,
    FpArray.filter(FpString.isString),
    FpArray.filter((behavior) => allBehaviors.includes(behavior))
  );
};

export const getEvents = (
  caseId: string,
  category: string,
  collectionIdObj: EventsPb.CollectionIdMessage.AsObject
) => {
  const { $eventService } = getServices();

  // convert the AsObject input back to message required by the api
  const collectionId = new EventsPb.CollectionIdMessage()
    .setCollection(collectionIdObj.collection)
    .setCollectionid(collectionIdObj.collectionid);

  const getRequest = (ordering: OrderingEnum) => {
    return new EventsPb.GetEventsRequest()
      .setCaseid(new CommonPb.OptionalString().setValue(caseId))
      .setCategoriesList([category])
      .setCollectionandid(collectionId)
      .setOrdering(ordering)
      .setLimit(10);
  };

  const msgsBeforeRequest = TaskEither.tryCatch(
    () => {
      const request = getRequest(OrderingEnum.NEWESTFIRST);
      log.info(`sending request to fetch before messages.`, request.toObject());
      return $eventService.getEvents(request, null);
    },
    (error) => {
      log.error(error, `received error response for before message. ${error} `);
      return new Error(`Error fetching msgs before flagged event ${error}`);
    }
  );
  const msgsAfterRequest = TaskEither.tryCatch(
    () => {
      const request = getRequest(OrderingEnum.OLDESTFIRST);
      log.info(`sending request to fetch after messages.`, request.toObject());
      return $eventService.getEvents(request, null);
    },
    (error) => {
      log.error(
        error,
        `received error response for after message. 
        [caseId: ${caseId}, category: ${category}, collectionIdObj: ${collectionIdObj}`
      );
      return new Error(`Error fetching msgs after flagged event ${error}`);
    }
  );
  return retryTask(TaskEither.sequenceArray([msgsBeforeRequest, msgsAfterRequest]));
};

export const getCaseLog = (caseId: string) => {
  const { $eventService } = getServices();
  const request = new EventsPb.GetCaseLogRequest().setCaseid(caseId).setOffset(0).setLimit(4);

  return pipe(
    TaskEither.tryCatch(
      () => $eventService.getCaseLog(request, null),
      (err) => rpcErrToError(err as RpcError)
    ),
    TaskEither.map((caseLogResponse) => caseLogResponse.getEntriesList()),
    TaskEither.match(
      (err) => {
        log.error(err, `Unable to get CaseLog for case ${caseId}`);
        return [];
      },
      (entriesList) =>
        pipe(
          entriesList,
          FpArray.map((caseLogMessage) => caseLogMessage)
        )
    )
  )();
};

export const msgToQueueData =
  (company: CompanyMessage.AsObject) =>
  (msg: EventsPb.UserContentMessage.AsObject): ModerationDataRow => {
    const defaultPriority = Priority.HIGH;
    const getDeepLinkedAttributes = () => ({
      attributeKey: "actor-id",
      linkTemplate: "https://admin.poshcrew.com/admin/users/{}/view_user"
    });
    const deepLinkedAttributes =
      msg.client === "89f6540b-a120-44b0-a5fa-ed7810d9a11d" ? getDeepLinkedAttributes() : undefined;

    // transform a Array<[K, V]> to {K: V}
    // this is needed so that the spc-details element can render the data properly.
    // TODO: ideally we should just hand off this data directly to the stencil component
    const metadataAttributes = pipe(
      msg.attributesMap,
      FpArray.reduce({}, (acc: { [key: string]: string }, attributes) => {
        acc[attributes[0]] = attributes[1];
        return acc;
      })
    );
    // the list of all behaviors for an org includes behaviors from every category.
    // this function just keeps the behaviors from this message's category.
    const getAllBehaviors = FpArray.filterMap((behavior: LicensedBehaviorMessage.AsObject) =>
      behavior.category === msg.category ? Option.of(behavior.behavior) : Option.none
    );

    const allLabels = getAllBehaviors(company.behaviorsList);

    const metadata = msg.casemetadata;
    const matchedOutputs =
      metadata?.rulesoutputList
        .filter((r) => r.matches)
        .map(
          (r) =>
            ({
              behavior: r.behavior,
              confidence: r.confidence,
              source: FlagSourceEnum.SPECTRUM,
              original: true
            } as LabelInformation)
        ) || [];
    const matchedBehaviors = matchedOutputs?.map((r) => r.behavior);

    const matchedPriorityResult = sortedPriorityRegexes.find(({ regex }) =>
      matchedBehaviors.some((s) => regex.test(s))
    );

    const getFilteredOutputs = () =>
      matchedOutputs.filter((output) => allLabels.includes(output.behavior));

    const labelAdjustments = metadata?.behavioradjustmentsList || [];

    const addedLabels = pipe(
      labelAdjustments,
      FpArray.filter(
        (adjustment) =>
          adjustment.adjustmenttype === AdjustmentTypeEnum.ADDED &&
          adjustment.source !== FlagSourceEnum.USER
      ),
      FpArray.map(
        ({ behavior, source }) => ({ behavior, source, original: false } as LabelInformation)
      ),
      FpArray.sort(labelInformationOrd)
    );

    const removedLabels = pipe(
      labelAdjustments,
      FpArray.filter((adjustment) => adjustment.adjustmenttype === AdjustmentTypeEnum.CLEARED),
      FpArray.map(
        ({ behavior, source }) => ({ behavior, source, original: true } as LabelInformation)
      ),
      FpArray.sort(labelInformationOrd)
    );

    const originalLabels = pipe(
      getFilteredOutputs(),
      FpArray.filter((output) => !removedLabels.map((r) => r.behavior).includes(output.behavior)),
      FpArray.sortBy([labelInformationOrd])
    );

    const userLabels = pipe(
      labelAdjustments,
      FpArray.filter(
        (adjustment) =>
          adjustment.source === FlagSourceEnum.USER &&
          adjustment.adjustmenttype !== AdjustmentTypeEnum.CLEARED
      ),
      FpArray.map(
        ({ behavior, source }) => ({ behavior, source, original: true } as LabelInformation)
      ),
      FpArray.filter((output) => !removedLabels.map((r) => r.behavior).includes(output.behavior)),
      FpArray.sort(labelInformationOrd)
    );

    const userNotes = pipe(
      labelAdjustments,
      FpArray.filter((adjustment) => adjustment.source === FlagSourceEnum.USER),
      FpArray.head,
      Option.map(({ notes }) => notes),
      Option.getOrElse(() => "")
    );

    const weight = metadata?.weight || 0;

    return {
      caseId: metadata?.caseid as string,
      category: msg.category,
      contents: msg.text,
      collectionIdsList: msg.collectionidsList || [],
      priority: matchedPriorityResult?.priority ?? defaultPriority,
      source: getMsgSource(metadata),
      sourceUserReport: isUserReport(metadata),
      status: msg.casemetadata?.status,
      labels: originalLabels,
      allLabels,
      addedLabels,
      removedLabels,
      userLabels,
      userNotes,
      labelAdjustments,
      language: msg.language?.value,
      metadata: metadataAttributes,
      deepLinkedAttributes,
      epochMillis: msg.timestamp?.epochmillis,
      weight
    };
  };

export class CaseModerationDataService {
  constructor(eventService: EventGrpcServiceClient, authService: AuthService) {
    this.eventService = eventService;
    this.authService = authService;
  }

  public static createFromDefaults(authService: AuthService) {
    const { $eventService } = getServices();

    return new CaseModerationDataService($eventService, authService);
  }

  private eventService: EventGrpcServiceClient;
  private authService: AuthService;

  private setClient<A>() {
    return (f: { setClient: (value: string) => A }) => {
      const company = this.authService.companyOrLogout();
      return f.setClient(company.clientid);
    };
  }

  /**
   *
   * Converts a given list of behaviors into an Option<predicate>
   * If there is only one behavior in the list then the corresponding behavior predicate is returned.
   * In case of multiple Behaviors they are OR-ed into a composite predicate.
   * In case of an empty list of behaviors none is returned
   */
  private setPredicateForBehaviors(filters: Partial<QueueFilters>) {
    return (predicate: EventsPb.PredicateMessage) => {
      const isPredicateNotRequired = (behaviors: string[]) => {
        //  we don't want to send a behavior predicate when
        // behavior is not set in filters i.e. undefined
        // OR is an empty array
        // OR is an array containing all behaviors.
        // All these scenarios mean the same thing: we want all the behaviors
        return (
          FpArray.isEmpty(behaviors) || behaviors.length === this.authService.allBehaviors.length
        );
      };

      const behaviors = filters.behaviors || [];

      return pipe(
        behaviors,
        isPredicateNotRequired,
        FpBoolean.fold(
          () => {
            const behaviorPredicate =
              new EventsPb.BehaviorPredicateMessage().setIncludedbehaviorsList(behaviors);
            return predicate.setBehaviorpredicate(behaviorPredicate);
          },
          () => predicate
        )
      );
    };
  }

  /**
   *
   * Converts attribute filter to Option<predicate>
   */
  private setPredicateForAttributes(filters: Partial<QueueFilters>) {
    return (predicate: EventsPb.PredicateMessage) => {
      const { attribute } = filters;
      const pattern = /([^:]+):\s*([^:]+)/;
      const match = attribute?.match(pattern);
      const name = match?.[1].trim() ?? "";
      const valueStr = match?.[2].trim() ?? "";

      return pipe(
        { name, valueStr },
        Option.fromPredicate(
          ({ name, valueStr }: { name: string; valueStr: string }) => !!name && !!valueStr
        ),
        Option.fold(
          () => predicate,
          ({ name, valueStr }) => {
            const attributesPredicate = new EventsPb.AttributePredicateMessage()
              .setAttributename(name)
              .setAcceptedvaluesList([valueStr]);
            return predicate.setAttributepredicate(attributesPredicate);
          }
        )
      );
    };
  }

  private setPredicateForSource(filters: Partial<QueueFilters>) {
    return (predicate: EventsPb.PredicateMessage) => {
      const source = filters.source || [];

      const isPredicateNotRequired = (source: FlagSourceEnum[]) => {
        return source.length === 0 || source.length === this.authService.allSources.length;
      };

      return pipe(
        source,
        isPredicateNotRequired,
        FpBoolean.fold(
          () => {
            const sourcePredicate =
              new EventsPb.CaseSourcePredicateMessage().setIncludedsourcesList(source);
            return predicate.setSourcepredicate(sourcePredicate);
          },
          () => predicate
        )
      );
    };
  }

  private setPredicateForLanguages(filters: Partial<QueueFilters>) {
    return (predicate: EventsPb.PredicateMessage) => {
      const languages = filters.languages || [];

      const includeUnknownLanguages = !!filters.includeUnknownLanguages;

      // language clause can be excluded if language array is empty OR contains all languages
      const isLanguageClauseExcluded = (languages: string[]) =>
        languages.length === 0 || languages.length === this.authService.allLanguages.length;

      // utility method to assess if we need language predicate at all
      const isPredicateNotRequired = ({
        languages,
        includeUnknownLanguages
      }: {
        languages: string[];
        includeUnknownLanguages: boolean;
      }) => {
        // a predicate is not required if we do not need to set includeUnknownLanguages AND language clasue can be excluded
        return isLanguageClauseExcluded(languages) && !includeUnknownLanguages;
      };

      const setLanguage = (predicate: EventsPb.LanguagePredicateMessage) => {
        return isLanguageClauseExcluded(languages)
          ? predicate
          : predicate.setIncludedlanguagesList(languages);
      };

      const setUnknownLanguage = (predicate: EventsPb.LanguagePredicateMessage) => {
        return includeUnknownLanguages ? predicate.setIncludeunknown(true) : predicate;
      };

      return pipe(
        { languages, includeUnknownLanguages },
        isPredicateNotRequired,
        FpBoolean.fold(
          () => {
            const languagePredicate = pipe(
              new EventsPb.LanguagePredicateMessage(),
              setLanguage,
              setUnknownLanguage
            );
            return predicate.setLanguagepredicate(languagePredicate);
          },
          () => predicate
        )
      );
    };
  }

  private setPredicateForCaseStatus(filters: Partial<QueueFilters>) {
    return (predicate: EventsPb.PredicateMessage) => {
      const caseStatuses = (filters.caseStatus && [...filters.caseStatus]) || [];

      // if the status filter has PendingReview in it then add VIEWEND and VIEWSTART to it as well
      const augmentWithViewStatuses = (statuses: CaseStatusEnum[]) => {
        return pipe(
          statuses,
          FpArray.findFirst((x) => x === CaseStatusEnum.PENDINGREVIEW),
          Option.fold(
            () => statuses,
            (_) => [...statuses, CaseStatusEnum.VIEWEND, CaseStatusEnum.VIEWSTART]
          )
        );
      };

      // Set a caseStatusPredicate if there are case status values or return the original predicate
      const setPredicate =
        (predicate: EventsPb.PredicateMessage) => (statuses: CaseStatusEnum[]) => {
          return pipe(
            NonEmptyArray.fromArray(statuses),
            Option.map((statuses) =>
              new EventsPb.CaseStatusPredicateMessage().setIncludedstatusesList(statuses)
            ),
            Option.map((p) => predicate.setCasestatuspredicate(p)),
            Option.getOrElse(() => predicate)
          );
        };

      return pipe(caseStatuses, augmentWithViewStatuses, setPredicate(predicate));
    };
  }

  private setCategoriesList(filters: Partial<QueueFilters>) {
    return (eventRequest: EventsPb.GetFlaggedEventsRequest) => {
      const categories = filters.categories || [];

      // We don't want to set the categories predicate on the request if all categories are selected or if array is empty
      return pipe(
        categories.length === 0 || categories.length === this.authService.categories.length,
        FpBoolean.fold(
          () => eventRequest.setCategoriesList(categories), // all categories are not selected, set the categories predicate
          () => eventRequest // all categories are selected, return the original request without any modifications
        )
      );
    };
  }

  private setPredicate(filterValues: Partial<QueueFilters>) {
    return (request: EventsPb.GetFlaggedEventsRequest) => {
      const predicate = pipe(
        new EventsPb.PredicateMessage(),
        this.setPredicateForBehaviors(filterValues),
        this.setPredicateForCaseStatus(filterValues),
        this.setPredicateForLanguages(filterValues),
        this.setPredicateForAttributes(filterValues),
        this.setPredicateForSource(filterValues)
      );

      const obj = predicate.toObject();

      const isValidPredicate =
        !!obj.behaviorpredicate ||
        !!obj.casestatuspredicate ||
        !!obj.languagepredicate ||
        !!obj.attributepredicate ||
        !!obj.sourcepredicate;

      return isValidPredicate ? request.setPredicate(predicate) : request;
    };
  }

  public getFlaggedEventsCount(filters: Partial<QueueFilters>) {
    const request = pipe(
      new EventsPb.GetFlaggedEventsRequest(),
      this.setClient(),
      this.setPredicate(filters),
      this.setCategoriesList(filters),
      (request) => request.setOrdering(OrderingEnum.NEWESTFIRST)
    );

    log.info("Fetching flagged event count", request.toObject());

    return pipe(
      request,
      TaskEither.tryCatchK(
        (request) => this.eventService.countFlaggedEvents(request, null),
        (err) => rpcErrToError(err as RpcError)
      ),
      TaskEither.map((response) => response.getCount())
    );
  }

  public getCases(filters: QueueFilters) {
    // setting a high limit of 1k because we expect flagged cases per user to be low,
    // server side paginating on this incurs additional housekeeping of cursors we will do it if needed
    const request = pipe(
      new EventsPb.GetFlaggedEventsRequest().setOrdering(OrderingEnum.NEWESTFIRST).setLimit(1000),
      this.setPredicate(filters),
      this.setCategoriesList(filters),
      this.setClient()
    );

    log.info("Fetching moderation cases", request.toObject());

    const responseToModerationRow = msgToQueueData(this.authService.companyOrLogout());

    return pipe(
      retryAPI(() => this.eventService.getFlaggedEvents(request, null)),
      TaskEither.map((response) => response.getMessagesList()),
      TaskEither.map((msgList) => msgList.map((m) => responseToModerationRow(m.toObject())))
    );
  }

  public addCaseLog(caseId: string, newStatus: CaseStatusEnum) {
    const email = this.authService.userOrLogout().email;

    const caseLogRequest = new EventsPb.AddCaseLogRequest()
      .setCaseid(caseId)
      .setCreatedby(email)
      .setNewstatus(newStatus);

    return pipe(
      caseLogRequest,
      TaskEither.tryCatchK(
        (request) => this.eventService.addCaseLog(request, null),
        (err) => rpcErrToError(err as RpcError)
      )
    );
  }
}

import { Store } from "vuex";
import { getModule } from "vuex-module-decorators";
import UserQueueStore from "~/store/userQueue";

let userQueueStore: UserQueueStore;

export const initializeUserQueueStore = (store: Store<any>): void => {
  userQueueStore = getModule(UserQueueStore, store);
};

export const getUserQueueStore = (): UserQueueStore => {
  return userQueueStore;
};
